import React, { useEffect, useState } from "react"
import {
  CardBody,
  Container,
  Row,
  Col,
  Card,
  Button,
  Table,
  Form,
  Label,
  Input,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { CSVLink } from "react-csv"
import jsPDF from "jspdf"
import "jspdf-autotable"
import { URLS } from "../../Url"

function PaymentReport() {
  const [Actin, setActin] = useState([])
  useEffect(() => {
    ActinReport()
  }, [])

  const [userInCsv, setuserInCsv] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const ActinReport = () => {
    var token = datas
    const data = { fromDate: "", toDate: "" }

    axios
      .post(URLS.UserListReports, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setActin(res.data.data)
        setuserInCsv(res.data.userlistExcel)
      })
  }

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = Actin.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Actin.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const [filter, setfilter] = useState(false)

  const [filters, setfilters] = useState({
    fromDate: "",
    toDate: "",
  })

  const getfilter = e => {
    e.preventDefault()
    GetActinFiliter()
  }

  const GetActinFiliter = () => {
    var token = datas
    const data = {
      fromDate: filters.fromDate,
      toDate: filters.toDate,
      status: filters.status,
    }
    axios
      .post(URLS.UserListReports, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setActin(res.data.data)
        setuserInCsv(res.data.userlistExcel)
        hidefilter()
        setfilters("")
      })
  }

  const hidefilter = () => setfilter(false)

  const handleChangeflt = e => {
    let myUser = { ...filters }
    myUser[e.target.name] = e.target.value
    setfilters(myUser)
  }

  const csvReport = {
    filename: "User_Wise_Report",
    data: userInCsv,
  }

  const exportPDF = () => {
    const unit = "pt"
    const size = "A2"
    const orientation = "portrait"
    const doc = new jsPDF(orientation, unit, size)
    doc.setFontSize(15)
    const headers = [
      [
        "SlNo",
        "Name",
        "Phone",
        "Email",
        "Gender ",
        "Age ",
        "Wallet Balance ",
        "Pending Bookings",
        "Completed Bookings",
        "Cancelled Bookings",
        "Status",
      ],
    ]

    const data = Actin.map((elt, i) => [
      i + 1,
      elt.name,
      elt.mobileNumber,
      elt.email,
      elt.gender,
      elt.age,
      elt.wallet,
      elt.PendingBookings,
      elt.CompletedBookings,
      elt.cancelledBookings,
      elt.status,
    ])
    let content = {
      startY: 50,
      head: headers,
      body: data,
    }
    doc.autoTable(content)
    doc.save("User_Wise_Report.pdf")
  }

  return (
    <div>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Go Cut" breadcrumbItem="Users  List Report" />
            {/* adding the fromDate and toDate */}

            {filter ? (
              <>
                <Card>
                  <CardBody>
                    <Form
                      onSubmit={e => {
                        getfilter(e)
                      }}
                    >
                      <Row>
                        <Col lg="3">
                          <div className="mb-3">
                            <Label for="basicpill-declaration-input10">
                              From Date <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="date"
                              required
                              className="form-control"
                              id="basicpill-Declaration-input10"
                              onChange={e => {
                                handleChangeflt(e)
                              }}
                              name="fromDate"
                              value={filters.fromDate}
                            />
                          </div>
                        </Col>
                        <Col lg="3">
                          <div className="mb-3">
                            <Label for="basicpill-declaration-input10">
                              To Date <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="date"
                              required
                              className="form-control"
                              id="basicpill-Declaration-input10"
                              onChange={e => {
                                handleChangeflt(e)
                              }}
                              name="toDate"
                              value={filters.toDate}
                            />
                          </div>
                        </Col>
                        <Col lg="3">
                          <div className="mt-4">
                            <Button type="submit" className="m-1" color="info">
                              <i className="fas fa-check-circle"></i> search
                            </Button>
                            <Button
                              onClick={hidefilter}
                              className="m-1"
                              color="danger"
                            >
                              <i className="fas fa-times-circle"></i> Cancel
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </>
            ) : (
              ""
            )}

            <Card>
              <CardBody>
                <div>
                  <div className="table-responsive">
                    <div className="col-sm-12">
                      <div style={{ float: "right" }}>
                        <Row>
                          <Col>
                            <div style={{ float: "right" }}>
                              <CSVLink {...csvReport}>
                                <button
                                  className="btn btn-success me-2"
                                  type="submit"
                                >
                                  <i className="fas fa-file-excel"></i> Excel
                                </button>
                              </CSVLink>
                              <Button
                                type="button"
                                className="btn btn-danger "
                                onClick={exportPDF}
                              >
                                <i className="fas fa-file-pdf"></i> Pdf
                              </Button>
                              <Button
                                className="m-1"
                                onClick={() => {
                                  setfilter(!filter)
                                }}
                                color="info"
                              >
                                <i className="fas fa-filter"></i> Filter
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div className="mt-3">
                      <Table
                        id="empTable"
                        className="table table-bordered mb-3 mt-5"
                      >
                        <thead className="text-center">
                          <tr className="text-center">
                            <th>SlNo</th>
                            <th>Name</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th>Gender </th>
                            <th>Age </th>
                            <th>Wallet Balance </th>
                            <th>Pending Bookings</th>
                            <th>Completed Bookings</th>
                            <th>Cancelled Bookings</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody className="text-center">
                          {lists.map((data, key) => (
                            <tr key={key} className="text-center">
                              <td>{(pageNumber - 1) * 5 + key + 6}</td>
                              <td>{data.name}</td>
                              <td>{data.mobileNumber}</td>
                              <td>{data.email}</td>
                              <td>{data.gender}</td>
                              <td>{data.age}</td>
                              <td>{data.wallet}</td>
                              <td>{data.PendingBookings}</td>
                              <td>{data.CompletedBookings}</td>
                              <td>{data.cancelledBookings}</td>
                              <td>
                                <span className="badge bg-success">
                                  {data.status}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                    <div className="mt-3" style={{ float: "right" }}>
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                        total={lists.length}
                      />
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    </div>
  )
}

export default PaymentReport
