import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  Table,
  Label,
  Form,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import gig from "../../assets/images/logos.png"
import ReactPaginate from "react-paginate"
import Select from "react-select"
import { URLS } from "../../Url"
import axios from "axios"

const Notifications = () => {
  const [form, setform] = useState([])

  const [Noti, setNoti] = useState([])

  const [customer, setcustomer] = useState([])

  const [Vendor, setVendor] = useState([])

  const [isLoading, setIsLoading] = useState(false)

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  const getNotifications = () => {
    var token = datas
    axios
      .post(
        URLS.GetNotifications,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setNoti(res.data.notifications)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    getNotifications()
    getactivecustomers()
    getactiveVendor()
  }, [])

  const getactivecustomers = () => {
    var token = datas

    axios
      .post(
        URLS.GetUser,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          setcustomer(res.data.userdetails)
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }
  const getactiveVendor = () => {
    var token = datas

    axios
      .post(
        URLS.GetVendors,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          setVendor(res.data.data)
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const [selectedMulti, setselectedMulti] = useState([])

  const [selectedMulti1, setselectedMulti1] = useState([])

  function handleMulti(data) {
    setselectedMulti(data)
  }

  function handleMulti1(data) {
    setselectedMulti1(data)
  }

  const options = customer.map(data => ({
    value: data._id,
    label: data.name,
  }))

  const options1 = Vendor.map(data => ({
    value: data._id,
    label: data.salonName,
  }))

  const addnotifi = () => {
    var token = datas

    const selectedValues =
      form.sendTo == "User" ? selectedMulti.map(option => option.value) : "All"
    const selectedValues1 =
      form.sendTo == "Vendor"
        ? selectedMulti1.map(option => option.value)
        : "All"

    const dataArray = {
      title: form.title,
      description: form.description,
      sendTo:
        form.sendTo == "All"
          ? "All"
          : form.sendTo == "AllVendors"
          ? "Vendor"
          : form.sendTo == "AllUsers"
          ? "User"
          : form.sendTo,
      users:
        form.sendTo == "All"
          ? "All"
          : form.sendTo == "User"
          ? selectedValues
          : form.sendTo == "Vendor"
          ? selectedValues1
          : "All",
    }

    axios
      .post(URLS.AddNotifications, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            setIsLoading(true)
            getNotifications()
            clearForm()
            setselectedMulti("")
            setselectedMulti1("")
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const deletenoti = data => {
    var token = datas
    var remid = data._id
    axios
      .delete(URLS.DeleteNotifications + remid, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getNotifications()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      deletenoti(data)
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    addnotifi()
  }

  const clearForm = () => {
    setform({
      sendTo: "",
      title: "",
      description: "",
    })
  }

  const [forms, setforms] = useState([])

  const handlechange = e => {
    let myUser = { ...forms }
    myUser[e.target.name] = e.target.value
    setforms(myUser)
    var token = datas
    axios
      .post(
        URLS.GetNotificationsSearch + `${e.target.value}`,
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setNoti(res.data.notifications)
      })
  }

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = Noti.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Noti.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Go Cut" breadcrumbItem="Notifications" />
          <Row>
            <Col md={4}>
              <Card className="p-4">
                <h5>Add Notification</h5>
                <Form
                  onSubmit={e => {
                    handleSubmit(e)
                  }}
                >
                  <div>
                    <div className="mt-3">
                      <Label>Title</Label>
                      <span className="text-danger">*</span>
                      <Input
                        value={form.title}
                        onChange={e => {
                          handleChange(e)
                        }}
                        name="title"
                        required
                        type="text"
                        placeholder="Enter Title"
                      />
                    </div>
                    <div className="mt-3">
                      <Label for="basicpill-firstname-input1">
                        Send To
                        <span className="text-danger">*</span>
                      </Label>
                      <select
                        value={form.sendTo}
                        name="sendTo"
                        onChange={e => {
                          handleChange(e)
                        }}
                        className="form-select"
                        required
                      >
                        <option value="">Select</option>
                        <option value="All">All </option>
                        <option value="AllVendors">All Vendors</option>
                        <option value="Vendor">Single Vendor</option>
                        <option value="AllUsers">All Customer's</option>
                        <option value="User">Single Customer</option>
                      </select>
                    </div>
                    {form.sendTo == "User" ? (
                      <div className="mt-3">
                        <Label>Customers</Label>
                        <span className="text-danger">*</span>
                        <Select
                          name="userList"
                          value={selectedMulti}
                          onChange={handleMulti}
                          options={options}
                          required
                          isMulti
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {form.sendTo == "Vendor" ? (
                      <div className="mt-3">
                        <Label>All Vendors</Label>
                        <span className="text-danger">*</span>
                        <Select
                          name="userList"
                          value={selectedMulti1}
                          onChange={handleMulti1}
                          options={options1}
                          required
                          isMulti
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="mt-3">
                      <Label>Description</Label>
                      <span className="text-danger">*</span>
                      <textarea
                        className="form-control"
                        value={form.description}
                        onChange={e => {
                          handleChange(e)
                        }}
                        name="description"
                        required
                        type="text"
                        placeholder="Description"
                      />
                    </div>
                  </div>
                  <div className="text-end mt-3">
                    <Button type="submit" color="primary m-1">
                      Submit <i className="bx bx-check-circle"></i>
                    </Button>
                  </div>
                </Form>
              </Card>
            </Col>
            <Col md={8}>
              <Card>
                {isLoading == true ? (
                  <>
                    <div
                      style={{ zIndex: "9999999999999", height: "420px" }}
                      className="text-center mt-5 pt-5"
                    >
                      <img src={gig} height="140px"></img>
                      <div>Loading......</div>
                    </div>
                  </>
                ) : (
                  <>
                    <CardBody>
                      <Row>
                        <Col></Col>
                        <Col>
                          <div style={{ float: "right" }}>
                            <Input
                              name="search"
                              value={forms.search}
                              onChange={handlechange}
                              type="search"
                              placeholder="Search..."
                            />
                          </div>
                        </Col>
                      </Row>
                      <div className="table-rep-plugin mt-4 table-responsive">
                        <Table hover bordered responsive>
                          <thead>
                            <tr className="text-center">
                              <th>Sl.No</th>
                              <th>Date / Time</th>
                              <th>Send To</th>
                              <th>Title</th>
                              <th>Description</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists.map((data, key) => (
                              <tr key={key} className="text-center">
                                <th>{(pageNumber - 1) * 5 + key + 6}</th>
                                <td>
                                  {data.date} / {data.time}
                                </td>
                                <td>{data.sendTo}</td>
                                <td>{data.title}</td>
                                <td>{data.description}</td>
                                <td>
                                  <Button
                                    onClick={() => {
                                      manageDelete(data)
                                    }}
                                    size="sm"
                                    className="m-1"
                                    color="danger"
                                  >
                                    <div className="d-flex">
                                      <i className="bx bx-trash px-1"></i>
                                      <small> Delete </small>
                                    </div>
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div className="mt-3" style={{ float: "right" }}>
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists.length}
                          />
                        </div>
                      </div>
                    </CardBody>
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </div>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default Notifications
