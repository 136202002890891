import React, { useState, useEffect } from "react"
import {
  CardBody,
  Container,
  Row,
  Col,
  Card,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer } from "react-toastify"
import classnames from "classnames"
import { useHistory } from "react-router-dom"
import axios from "axios"
import { URLS } from "../../Url"
import StarRatings from "react-star-ratings"

function State() {
  const [forms, setforms] = useState([])

  const [activeTab1, setactiveTab1] = useState("5")

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }

  const history = useHistory()

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  useEffect(() => {
    GetOneOrder()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const OrderId = sessionStorage.getItem("CampaignId")

  const GetOneOrder = () => {
    const data = {
      vendorcampaignId: OrderId,
    }

    var token = datas
    axios
      .post(URLS.VendorsCamaignsId, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setforms(res.data.data[0])
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Go Cut" breadcrumbItem="View Vendor Campagin" />
          <Row>
            <Col>
              <Button
                onClick={() => history.goBack()}
                className="mb-3  m-1 "
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i> Back
              </Button>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col md={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={8}>
                      <Nav pills className="navtab-bg nav-justified">
                        <NavItem>
                          <NavLink
                            style={{
                              border: "1px #63183F solid",
                              borderRadius: "5px",
                              padding: "5px",
                              margin: "4px",
                            }}
                            className={classnames({
                              active: activeTab1 === "5",
                            })}
                            onClick={() => {
                              toggle1("5")
                            }}
                          >
                            View Campaign
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{
                              border: "1px #63183F solid",
                              borderRadius: "5px",
                              padding: "5px",
                              margin: "4px",
                            }}
                            className={classnames({
                              active: activeTab1 === "6",
                            })}
                            onClick={() => {
                              toggle1("6")
                            }}
                          >
                            Store Details
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Col>
                  </Row>
                  <TabContent
                    activeTab={activeTab1}
                    className="p-4  text-muted"
                  >
                    <TabPane tabId="5">
                      <h5 className="text-primary">View Campaign :-</h5>
                      <Row className="mt-5">
                        <Col md="6">
                          <ul className="verti-timeline list-unstyled">
                            <li className="event-list  mt-2">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 ">
                                      Campaign Name
                                    </h6>
                                    <p className="text-muted">
                                      {forms?.campaignName}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 ">
                                      Description
                                    </h6>
                                    <p className="text-muted">
                                      {forms?.description}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 ">
                                      Start Date
                                    </h6>
                                    <p className="text-muted">
                                      {forms?.startDate?.slice(0,10)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 ">
                                      Service Value
                                    </h6>
                                    <p className="text-muted">
                                      {forms?.serviceValue}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </Col>
                        <Col md="6">
                          <ul className="verti-timeline list-unstyled">
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 ">
                                      Service Name
                                    </h6>
                                    <p className="text-muted">
                                      {forms?.serviceName}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 ">End Date</h6>
                                    <p className="text-muted">
                                      {" "}
                                      {forms?.endDate?.slice(0,10)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 ">Discount</h6>
                                    <p className="text-muted">
                                      {" "}
                                      {forms?.discount}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="6">
                      <Row className="mt-3">
                        <Col lg={4}>
                          <h5 className="mb-4 text-primary">
                            Store Information :-
                          </h5>
                          <ul className="list-unstyled vstack gap-3 mb-0">
                            <img
                              src={URLS.Base + forms.salonLogo}
                              height="150px"
                              width="50%"
                            ></img>
                            <li>
                              <div className="d-flex mt-3">
                                <i className="bx bx-buildings font-size-18 text-primary"></i>
                                <div className="ms-3">
                                  <h6 className="mb-1 fw-semibold">
                                    Salon Name:
                                  </h6>
                                  <span className="text-muted">
                                    {forms.salonName}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex  mt-3">
                                <i className="bx bx-phone font-size-18 text-primary"></i>
                                <div className="ms-3">
                                  <h6 className="mb-1 fw-semibold">
                                    Salon Contact Number:
                                  </h6>
                                  {forms.salonMobileNumber}
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex mt-3">
                                <i className="bx bx-cut font-size-18 text-primary"></i>
                                <div className="ms-3">
                                  <h6 className="mb-1 fw-semibold">Seating:</h6>
                                  <span className="text-muted">
                                    {forms.salonSeating}
                                  </span>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </Col>
                        <Col lg={4}>
                          <ul className="verti-timeline list-unstyled ">
                            <h5 className="mb-4 text-primary">
                              Address Details :-
                            </h5>
                            <li className="event-list mt-5">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Address
                                    </h6>
                                    <p className="text-muted">
                                      {forms.salonAddress}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">City</h6>
                                    <p className="text-muted">
                                      {forms.salonCity}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Lattitude - Longitude
                                    </h6>
                                    <p className="text-muted">
                                      {forms.salonLatitude} -
                                      {forms.salonLongitude}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 ">Status</h6>
                                    <p className="text-muted">{forms.status}</p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </Col>
                        <Col md="4">
                          <h5 className=" text-primary mb-5">
                            Vendor Information :-
                          </h5>
                          <ul className="verti-timeline list-unstyled">
                            <li className="event-list  mt-2">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14">Name</h6>
                                    <p className="text-muted">
                                      {forms.vendorName}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14">Mobile</h6>
                                    <p className="text-muted">
                                      {forms.vendorMobileNumber}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14">Email</h6>
                                    <p className="text-muted">
                                      {forms.vendorEmail}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14">
                                      Designation
                                    </h6>
                                    <p className="text-muted">
                                      {forms.vendorDesignation}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default State
