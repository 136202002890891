import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, CardBody, Table } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ReactApexChart from "react-apexcharts"
import { URLS } from "../../Url"
import axios from "axios"

const Dashboard = () => {
  const [dash, setdash] = useState([])

  const [MonthlyVendors, setMonthlyVendors] = useState([])

  const [MonthlyUsers, setMonthlyUsers] = useState([])

  const [Customers, setCustomers] = useState([])

  const [Vendors, setVendors] = useState([])

  useEffect(() => {
    getdashdata()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const getdashdata = () => {
    var token = datas

    axios
      .post(
        URLS.GetDashboard,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setdash(res.data)
        setMonthlyVendors(res.data.monthlyVendors)
        setMonthlyUsers(res.data.monthlyUsers)
        setCustomers(res.data.latestusers)
        setVendors(res.data.latestvendors)
      })
  }

  const reports = [
    {
      title: "Vendors",
      iconClass: "bx bx-user-pin",
      description: dash.totalVendors,
    },
    {
      title: "Users",
      iconClass: "bx bx-user-circle",
      description: dash.totalUsers,
    },
    {
      title: "Services",
      iconClass: "bx-archive-in",
      description: dash.vendorServices,
    },
    {
      title: "Bookings",
      iconClass: "bx-purchase-tag-alt",
      description: dash.totalBookings,
    },
    {
      title: "User Amount",
      iconClass: "bx bx-rupee",
      description: dash.amount,
    },
    {
      title: "Vendor Amount",
      iconClass: "bx bx-user-check",
      description: dash.vendorAmount,
    },
    {
      title: "Gocut Amount",
      iconClass: "bx bxs-user-detail",
      description: dash.gocutAmount,
    },
  ]

  const series = [
    {
      name: "Customers",
      data: MonthlyUsers,
    },
    {
      name: "Vendors",
      data: MonthlyVendors,
    },
    // {
    //   name: "Amount",
    //   data: MonthlyAmount,
    // },
  ]

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "34%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },

    colors: ["#63183F", "#f1b44c", "#02B4A8"],
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yaxis: {
      title: {
        text: " (data)",
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val
        },
      },
    },
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Go Cut"} breadcrumbItem={"Dashboard"} />
          <Row>
            <Col xl="12">
              <Row>
                {reports.map((report, key) => (
                  <Col md="3" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
              <Card>
                <CardBody>
                  <ReactApexChart
                    options={options}
                    series={series}
                    type="bar"
                    height={350}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col lg="6">
              <Card>
                <CardBody>
                  <div className="table-rep-plugin mt-2 table-responsive">
                    <h5>Latest Vendors</h5>
                    <Table hover className="table table-bordered mb-4">
                      <thead>
                        <tr className="text-center">
                          <th>SlNo</th>
                          <th>Vendor Name </th>
                          <th>Vendor Phone</th>
                          <th>Vendor Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Vendors.map((data, key) => (
                          <tr key={key} className="text-center">
                            <td>{key + 1}</td>
                            <td>{data.name}</td>
                            <td>{data.mobileNumber}</td>
                            <td>{data.email}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6">
              <Card>
                <CardBody>
                  <div className="table-rep-plugin mt-2 table-responsive">
                    <h5>Latest Users</h5>
                    <Table hover className="table table-bordered mb-4">
                      <thead>
                        <tr className="text-center">
                          <th>SlNo</th>
                          <th>User Name </th>
                          <th>User Phone</th>
                          <th>User Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Customers.map((data, key) => (
                          <tr key={key} className="text-center">
                            <td>{key + 1}</td>
                            <td>{data.name}</td>
                            <td>{data.mobileNumber}</td>
                            <td>{data.email}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>{" "}
    </React.Fragment>
  )
}

export default Dashboard
