import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  Table,
  Modal,
  Form,
  Label,
  Container,
} from "reactstrap"
import { useHistory } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import ReactPaginate from "react-paginate"
import { ToastContainer, toast } from "react-toastify"
import { URLS } from "../../Url"
import gig from "../../assets/images/logos.png"

function Ventures() {
  const [Actin, setActin] = useState([])

  const [form2, setform2] = useState([])

  const [modal_small, setmodal_small] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const history = useHistory()

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  useEffect(() => {
    GetUsers()
    datass()
  }, [])

  const GetUsers = () => {
    var token = datas
    axios
      .post(
        URLS.PendingGetVendor,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.data)
        setIsLoading(false)
      })
  }

  const pagesVisited = pageNumber * listPerPage
  const lists = Actin.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Actin.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const OrderId1 = data => {
    sessionStorage.setItem("PendindBookId", data._id)
    history.push("/ViewPendingVendors")
  }

  const datass = () => {
    const location = sessionStorage.getItem("tost")
    if (location != "") {
      toast(location)
      sessionStorage.clear()
    } else {
      sessionStorage.clear()
    }
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const [Searchs, setSearchs] = useState([])

  const Search = e => {
    let myUser = { ...Searchs }
    myUser[e.target.name] = e.target.value
    setSearchs(myUser)
    var token = datas
    axios
      .post(
        URLS.PendingGetVendorSearch + `${e.target.value}`,
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.data)
      })
  }

  const [form1, setform1] = useState([])

  const getpopup = data => {
    setform1(data)
    tog_small()
  }

  function tog_small() {
    setmodal_small(!modal_small)
  }

  const handleChange2 = e => {
    let myUser = { ...form2 }
    myUser[e.target.name] = e.target.value
    setform2(myUser)
  }

  const handleSubmit1 = e => {
    e.preventDefault()
    Editstate()
  }

  const Editstate = () => {
    var token = datas
    const dataArray = {
      // status: form1.status,
      vendorId: form1._id,
    }
    axios
      .post(URLS.UpdateVendors, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            GetUsers()
            toast(res.data.message)
            setmodal_small(false)
            setform1({
              status: "",
            })
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Go Cut" breadcrumbItem="Pending Vendors List" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <div style={{ float: "right" }}>
                        <Input
                          type="search"
                          name="search"
                          value={Searchs.search}
                          onChange={Search}
                          className="form-control"
                          placeholder="Search.."
                          autoComplete="off"
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="table-rep-plugin table-responsive">
                    <Table hover className="table table-bordered mb-4 mt-5">
                      <thead>
                        <tr className="text-center">
                          <th>SlNo</th>
                          <th>Salon Name</th>
                          <th>Vendor Name</th>
                          <th>Mobile Number</th>
                          <th>Seating </th>
                          <th>Email</th>
                          <th>Address</th>
                          <th>Verified Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lists.map((data, key) => (
                          <tr key={key} className="text-center">
                            <td>{(pageNumber - 1) * 5 + key + 6}</td>
                            <td>{data.salonName}</td>
                            <td>{data.name}</td>
                            <td>{data.mobileNumber}</td>
                            <td>{data.salonSeating}</td>
                            <td>{data.email}</td>
                            <td>{data.salonAddress}</td>
                            <td>
                              {data.isverified == true ? (
                                <>
                                  <span className="badge bg-success">
                                    Approved
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span className="badge bg-warning">
                                    Pending
                                  </span>
                                </>
                              )}
                            </td>
                            <td>
                              <Button
                                onClick={() => {
                                  getpopup(data)
                                }}
                                size="sm"
                                className="m-1"
                                color="success"
                              >
                                <div className="d-flex">
                                  <i className="bx bx-edit px-1"></i>
                                  <small>Status</small>
                                </div>
                              </Button>
                              <Button
                                onClick={() => {
                                  OrderId1(data)
                                }}
                                size="sm"
                                className="m-1"
                                color="info"
                              >
                                <div className="d-flex">
                                  <i className="fas fa-eye px-1"></i>
                                  {""}
                                  <small> View </small>
                                </div>
                              </Button>{" "}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div
                      className="d-flex mt-3 mb-1"
                      style={{ float: "right" }}
                    >
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                        total={lists.length}
                      />
                    </div>
                  </div>{" "}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="md"
            isOpen={modal_small}
            toggle={() => {
              tog_small()
            }}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="mySmallModalLabel">
                Vendor Request
              </h5>
              <button
                onClick={() => {
                  setmodal_small(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Form
                onSubmit={e => {
                  handleSubmit1(e)
                }}
              >
                <div className="mb-3">
                  <Label>Status </Label> <span className="text-danger">*</span>
                  <select
                    name="paymentstatus"
                    value={form2.paymentstatus}
                    required
                    className="form-select"
                    onChange={e => {
                      handleChange2(e)
                    }}
                  >
                    <option value="">Select</option>
                    <option value="Accept">Accept </option>
                    {/* <option value="Reject">Reject </option> */}
                  </select>{" "}
                </div>

                {/* {form2.paymentstatus == "Reject" ? (
                  <>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Reject Reason <span className="text-danger">*</span>
                      </Label>
                      <textarea
                        type="text"
                        rows="4"
                        className="form-control "
                        id="basicpill-firstname-input1"
                        placeholder="Enter Reason"
                        required
                        value={form2.Reason}
                        name="Reason"
                        onChange={e => {
                          handleChange2(e)
                        }}
                      />
                    </div>
                  </>
                ) : (
                  ""
                )} */}
                <div style={{ float: "right" }}>
                  <Button
                    onClick={() => {
                      setmodal_small(false)
                    }}
                    color="danger"
                    type="button"
                  >
                    Cancel <i className="fas fa-times-circle"></i>
                  </Button>
                  <Button className="m-1" color="primary" type="submit">
                    Submit <i className="fas fa-check-circle"></i>
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Ventures
