import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody, Input, Button, Table } from "reactstrap"
import { useHistory } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import ReactPaginate from "react-paginate"
import { ToastContainer, toast } from "react-toastify"
import { URLS } from "../../Url"

function Ventures() {
  const [Actin, setActin] = useState([])

  const history = useHistory()

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const [Plans, setPlans] = useState([])
  
  const GetAllPlans = () => {
    var token = datas
    axios
      .post(
        URLS.GetUserPlans,
        { type: "Vendor" },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setPlans(res.data.data)
      })
  }

  useEffect(() => {
    GetUsers()
    datass()
    GetAllPlans()
  }, [])

  const handleChange = e => {
    var token = datas
    const bodydata ={
      planId: e.target.value
    }
    axios
      .post(
        URLS.getVendorplanWiseList,bodydata
        ,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.data)
      })
  }

  const GetUsers = () => {
    var token = datas
    axios
      .post(
        URLS.getVendorplanWiseList,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.data)
      })
  }

  const pagesVisited = pageNumber * listPerPage
  const lists = Actin.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Actin.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const OrderId1 = data => {
    sessionStorage.setItem("UserId", data.vendorId)
    history.push("/ViewVendor")
  }

  const datass = () => {
    const location = sessionStorage.getItem("tost")
    if (location != "") {
      toast(location)
      sessionStorage.clear()
    } else {
      sessionStorage.clear()
    }
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const [Searchs, setSearchs] = useState([])

  const Search = e => {
    let myUser = { ...Searchs }
    myUser[e.target.name] = e.target.value
    setSearchs(myUser)
    var token = datas
    axios
      .post(
        URLS.getVendorplanWiseListSearch + `${e.target.value}`,
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.data)
      })
  }



  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Go Cut"
            breadcrumbItem="Vendors Subscription List"
          />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col md="6" />
                    <Col md="3">
                      <select className="form-select" onChange={handleChange} name="planId">
                          <option value="">Select Category</option>
                          {Plans.map((data, index)=>(
                          <option key={index} value={data._id}>{data.userPlanName}</option>
                        ))}
                        </select>
                      
                    </Col>
                    <Col md="3">
                        <Input
                          type="search"
                          name="search"
                          value={Searchs.search}
                          onChange={Search}
                          className="form-control"
                          placeholder="Search.."
                          autoComplete="off"
                        />
                      
                    </Col>
                   
                  </Row>
                  <div className="table-rep-plugin table-responsive">
                    <Table hover className="table table-bordered mb-4 mt-3">
                      <thead>
                        <tr className="text-center">
                          <th>SlNo</th>
                          <th>Salon Name</th>
                          <th>Vendor Name</th>
                          <th>Mobile Number</th>
                          <th>Email</th>
                          <th>Plan Name </th>
                          <th>Subscription Purchase Date </th>
                          <th>Subscription Expire Date</th>
                          <th>Plan Amount</th>
                          <th>Plan Transaction Id</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lists.map((data, key) => (
                          <tr key={key} className="text-center">
                            <td>{(pageNumber - 1) * 5 + key + 6}</td>
                            <td>{data.salonName}</td>
                            <td>{data.vendorName}</td>
                            <td>{data.vendorPhone}</td>
                            <td>{data.vendorEmail}</td>
                            <td>{data.planName}</td>
                            <td>
                              {data.startDate}/{data.startTime}
                            </td>
                            <td>
                              {data.endDate}/{data.endTime}
                            </td>
                            <td>{data.finalPrice}</td>
                            <td>{data.transactionId}</td>
                            <td>
                              <span className="badge bg-success">
                                {data.transactionStatus}
                              </span>
                            </td>
                            <td>
                              <Button
                                onClick={() => {
                                  OrderId1(data)
                                }}
                                size="sm"
                                className="m-1"
                                color="info"
                              >
                                <div className="d-flex">
                                  <i className="fas fa-eye px-1"></i>
                                  {""}
                                  <small> View </small>
                                </div>
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div
                      className="d-flex mt-3 mb-1"
                      style={{ float: "right" }}
                    >
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                        total={lists.length}
                      />
                    </div>
                  </div>{" "}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ToastContainer />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Ventures
