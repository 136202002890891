import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody, Input, Table, Button } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer } from "react-toastify"
import ReactPaginate from "react-paginate"
import { URLS } from "../../Url"
import axios from "axios"
import { useHistory } from "react-router-dom"

function Ventures() {
  const [Actin, setActin] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const VendorPlanId = sessionStorage.getItem("Planid")

  useEffect(() => {
    GetUsers()
  }, [])

  const GetUsers = () => {
    var token = datas
    axios
      .post(
        URLS.GetPlanId,
        { planId: VendorPlanId },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.result)
      })
  }

  const pagesVisited = pageNumber * listPerPage
  const lists = Actin.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Actin.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const [Searchs, setSearchs] = useState([])

  const Search = e => {
    let myUser = { ...Searchs }
    myUser[e.target.name] = e.target.value
    setSearchs(myUser)
    var token = datas
    axios
      .post(
        URLS.GetPlanIdSearch + `${e.target.value}`,
        { planId: VendorPlanId },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.result)
      })
  }

  const history = useHistory()

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Go Cut" breadcrumbItem="Vendor Subscrbers list" />
          <Row>
            <Col>
              <Button
                onClick={() => history.goBack()}
                className="mb-3  m-1 "
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i> Back
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <div className="table-rep-plugin mt-4 table-responsive">
                    <Table hover className="table table-bordered mb-4 ">
                      <thead>
                        <tr className="text-center">
                          <th>SlNo</th>
                          <th>Name</th>
                          <th>Phone</th>
                          <th>Plan Name</th>
                          <th>Plan Price</th>
                          <th>Plan Offer Price </th>
                          <th>Plan Start Date_Time</th>
                          <th>Plan End Date_Time</th>
                          <th>Transaction Id</th>
                          <th>Transaction Status</th>
                          <th>Plan Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lists.map((data, key) => (
                          <tr key={key} className="text-center">
                            <td>{(pageNumber - 1) * 5 + key + 6}</td>
                            <td>{data.vendorName}</td>
                            <td>{data.vendorPhone}</td>
                            <td>{data.planName}</td>
                            <td>{data.finalPrice}</td>
                            <td>{data.planOfferPrice}</td>
                            <td>
                              {data.startDate}/{data.startTime}
                            </td>
                            <td>
                              {data.endDate}/{data.endTime}
                            </td>
                            <td>{data.transactionId}</td>
                            <td>{data.transactionStatus}</td>
                            <td>
                              {data.planStatus == "active" ? (
                                <>
                                  <span className="badge bg-success ">
                                    {data.planStatus}
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span className="badge bg-danger ">
                                    {data.planStatus}
                                  </span>
                                </>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div
                      className="d-flex mt-3 mb-1"
                      style={{ float: "right" }}
                    >
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                        total={lists.length}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ToastContainer />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Ventures
