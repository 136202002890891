// import React, { useEffect, useState } from "react"
// import {
//   CardBody,
//   CardHeader,
//   Container,
//   Row,
//   Col,
//   Card,
//   CardTitle,
//   Form,
//   Label,
//   Input,
//   Button,
//   Table,
//   Modal,
// } from "reactstrap"
// import Breadcrumbs from "../../components/Common/Breadcrumb"
// import { ToastContainer, toast } from "react-toastify"
// import ReactPaginate from "react-paginate"
// import axios from "axios"
// import { URLS } from "../../Url"
// import gig from "../../assets/images/logos.png"
// import Select from "react-select"

// const Coupons = () => {
//   const [modal_small, setmodal_small] = useState(false)

//   const [isLoading, setIsLoading] = useState(false)

//   const [selectedOptions1, setSelectedOptions1] = useState([])

//   function HealthStandard(data) {
//     setSelectedOptions1(data)
//   }

//   const [selectedOptions2, setSelectedOptions2] = useState([])

//   function HealthStandard2(data) {
//     setSelectedOptions2(data)
//   }

//   function tog_small() {
//     setmodal_small(!modal_small)
//     removeBodyCss()
//   }

//   var gets = localStorage.getItem("authUser")
//   var data = JSON.parse(gets)
//   var datas = data.token

//   const [coup, setcoup] = useState([])
//   const [form, setform] = useState({
//     couponType: "",
//     title: "",
//     couponCode: "",
//     limitForSameUser: "",
//     couponCodeType: "",
//     price: "",
//     minimunPurchase: "",
//     maximumDiscount: "",
//     fromDate: "",
//     toDate: "",
//     percentage: "",
//   })
//   const [form1, setform1] = useState([])

//   const handleChange = e => {
//     let myUser = { ...form }
//     myUser[e.target.name] = e.target.value
//     setform(myUser)
//   }
//   const handleChange1 = e => {
//     let myUser = { ...form1 }
//     myUser[e.target.name] = e.target.value
//     setform1(myUser)
//   }

//   const getAllcoupons = () => {
//     var token = datas
//     axios
//       .post(
//         URLS.GetCoupon,
//         {},
//         {
//           headers: { Authorization: `Bearer ${token}` },
//         }
//       )
//       .then(res => {
//         setcoup(res.data.cpns)
//         setIsLoading(false)
//       })
//   }

//   const addcoupons = () => {
//     var token = datas

//     const dataArray = {
//       couponType: form.couponType,
//       title: form.title,
//       couponCode: form.couponCode,
//       limitForSameUser: form.limitForSameUser,
//       couponCodeType: form.couponCodeType,
//       price: form.price,
//       minimunPurchase: form.minimunPurchase,
//       maximumDiscount: form.maximumDiscount,
//       fromDate: form.fromDate,
//       toDate: form.toDate,
//       percentage: form.percentage,
//       userList: selectedOptions1,
//     }

//     axios
//       .post(URLS.AddCoupon, dataArray, {
//         headers: { Authorization: `Bearer ${token}` },
//       })
//       .then(
//         res => {
//           if (res.status === 200) {
//             toast(res.data.message)
//             getAllcoupons()
//             clearForm()
//             setIsLoading(true)
//             setSelectedOptions1("")
//           }
//         },
//         error => {
//           if (error.response && error.response.status === 400) {
//             toast(error.response.data.message)
//           }
//         }
//       )
//   }

//   const getpopup = data => {
//     setform1(data)
//     setSelectedOptions2(data.users)
//     tog_small()
//   }

//   const editcoupon = () => {
//     var token = datas
//     var formid = form1._id

//     const dataArray = {
//       couponType: form1.couponType,
//       title: form1.title,
//       couponCode: form1.couponCode,
//       limitForSameUser: form1.limitForSameUser,
//       couponCodeType: form1.couponCodeType,
//       price: form1.price,
//       minimunPurchase: form1.minimunPurchase,
//       maximumDiscount: form1.maximumDiscount,
//       fromDate: form1.fromDate,
//       toDate: form1.toDate,
//       percentage: form1.percentage,
//       userList: selectedOptions2,
//     }

//     axios
//       .put(URLS.UpdateCoupon + "/" + formid, dataArray, {
//         headers: { Authorization: `Bearer ${token}` },
//       })
//       .then(
//         res => {
//           if (res.status === 200) {
//             toast(res.data.message)
//             getAllcoupons()
//             setmodal_small(false)
//             setIsLoading(true)
//           }
//         },
//         error => {
//           if (error.response && error.response.status === 400) {
//             toast(error.response.data.message)
//           }
//         }
//       )
//   }

//   const handleSubmit = e => {
//     e.preventDefault()
//     addcoupons()
//   }

//   const handleSubmit1 = e => {
//     e.preventDefault()
//     editcoupon()
//   }

//   const deletebenners = data => {
//     var token = datas
//     var remid = data._id
//     axios
//       .delete(URLS.InActiveCoupon + "/" + remid, {
//         headers: { Authorization: `Bearer ${token}` },
//       })
//       .then(
//         res => {
//           if (res.status === 200) {
//             toast(res.data.message)
//             getAllcoupons()
//           }
//         },
//         error => {
//           if (error.response && error.response.status === 400) {
//             toast(error.response.data.message)
//           }
//         }
//       )
//   }

//   const manageDelete = data => {
//     const confirmBox = window.confirm("Do you really want to Delete?")
//     if (confirmBox === true) {
//       deletebenners(data)
//     }
//   }

//   const [listPerPage] = useState(5)
//   const [pageNumber, setPageNumber] = useState(0)

//   const pagesVisited = pageNumber * listPerPage
//   const lists = coup.slice(pagesVisited, pagesVisited + listPerPage)
//   const pageCount = Math.ceil(coup.length / listPerPage)
//   const changePage = ({ selected }) => {
//     setPageNumber(selected)
//   }
//   useEffect(() => {
//     getAllcoupons()
//   }, [])

//   const clearForm = () => {
//     setform({
//       couponType: "",
//       title: "",
//       couponCode: "",
//       limitForSameUser: "",
//       couponCodeType: "",
//       price: "",
//       minimunPurchase: "",
//       maximumDiscount: "",
//       fromDate: "",
//       toDate: "",
//       percentage: "",
//     })
//   }

//   const [search, setsearch] = useState([])

//   const searchAll = e => {
//     let myUser = { ...search }
//     myUser[e.target.name] = e.target.value
//     setsearch(myUser)

//     var token = datas
//     axios
//       .post(
//         URLS.GetCouponSearch + `${e.target.value}`,
//         {},
//         {
//           headers: { Authorization: `Bearer ${token}` },
//         }
//       )
//       .then(res => {
//         setcoup(res.data.cpns)
//       })
//   }

//   const [dds, setdds] = useState([])

//   const handleChangedates = e => {
//     let myUser = { ...form }
//     myUser[e.target.name] = e.target.value
//     setform(myUser)

//     const tomorrow = new Date(e.target.value)
//     tomorrow.setDate(tomorrow.getDate() + 1)
//     setdds(tomorrow.toISOString().split("T")[0])
//   }

//   const [dds1, setdds1] = useState([])

//   const handleChangedates1 = e => {
//     let myUser = { ...form1 }
//     myUser[e.target.name] = e.target.value
//     setform1(myUser)

//     const tomorrow = new Date(e.target.value)
//     tomorrow.setDate(tomorrow.getDate() + 1)
//     setdds1(tomorrow.toISOString().split("T")[0])
//   }

//   const [Users, setUsers] = useState([])

//   useEffect(() => {
//     GetUsers()
//   }, [])

//   const GetUsers = () => {
//     var token = datas
//     axios
//       .post(
//         URLS.GetUsersDropdown,
//         {},
//         {
//           headers: { Authorization: `Bearer ${token}` },
//         }
//       )
//       .then(res => {
//         setUsers(res.data.userDropdown)
//       })
//   }

//   const [ins, setins] = useState(false)

//   const [Instructions, setInstructions] = useState([])

//   function inst() {
//     setins(!ins)
//   }

//   const getinc = data => {
//     setInstructions(data.users)
//     inst()
//   }

//   return (
//     <React.Fragment>
//       <div className="page-content">
//         <Container fluid>
//           <Breadcrumbs title="Go Cut" breadcrumbItem="Coupons" />
//           <Row>
//             <Col md={12}>
//               <Card>
//                 <CardHeader className="bg-white">
//                   <CardTitle>Add Coupons</CardTitle>
//                 </CardHeader>
//                 <CardBody>
//                   <Form
//                     onSubmit={e => {
//                       handleSubmit(e)
//                     }}
//                   >
//                     <Row>
//                       <Col md={4}>
//                         <div className="mb-3">
//                           <Label for="basicpill-firstname-input1">
//                             Coupon Type
//                             <span className="text-danger">*</span>
//                           </Label>
//                           <select
//                             name="couponType"
//                             value={form.couponType}
//                             onChange={e => {
//                               handleChange(e)
//                             }}
//                             required
//                             className="form-select"
//                           >
//                             <option value="">Select</option>
//                             <option value="Default">Default</option>
//                             <option value="FirstOrder">Plan Type</option>
//                           </select>
//                         </div>
//                       </Col>
//                       {form.couponType == "FirstOrder" ? (
//                         <Col md={4}>
//                           <div className="mb-3">
//                             <Label for="basicpill-firstname-input1">
//                               Plans Wise
//                               <span className="text-danger">*</span>
//                             </Label>
//                             <select
//                               name="plans"
//                               value={form.plans}
//                               onChange={e => {
//                                 handleChange(e)
//                               }}
//                               required
//                               className="form-select"
//                             >
//                               <option value="">Select</option>
//                               <option value="ClassicPlan">Classic Plan</option>
//                             </select>
//                           </div>
//                         </Col>
//                       ) : (
//                         <></>
//                       )}
//                       {form.couponType == "FirstOrder" ? (
//                         <Col md={4}>
//                           <div className="mb-3">
//                             <Label for="basicpill-firstname-input1">
//                               Users
//                               <span className="text-danger">*</span>
//                             </Label>
//                             <select
//                               name="users"
//                               value={form.users}
//                               onChange={e => {
//                                 handleChange(e)
//                               }}
//                               required
//                               className="form-select"
//                             >
//                               <option value="">Select</option>
//                               <option value="Rahul">Rahul</option>
//                             </select>
//                           </div>
//                         </Col>
//                       ) : (
//                         <></>
//                       )}
//                       <Col md={4}>
//                         <div className="mb-3">
//                           <Label for="basicpill-firstname-input2">
//                             Title <span className="text-danger">*</span>
//                           </Label>
//                           <Input
//                             type="text"
//                             className="form-control"
//                             id="basicpill-firstname-input2"
//                             placeholder="Enter Title"
//                             required
//                             name="title"
//                             value={form.title}
//                             onChange={e => {
//                               handleChange(e)
//                             }}
//                           />
//                         </div>
//                       </Col>
//                       <Col md={4}>
//                         <div className="mb-3">
//                           <Label for="basicpill-firstname-input3">
//                             Coupon Code <span className="text-danger">*</span>
//                           </Label>
//                           <Input
//                             type="text"
//                             className="form-control"
//                             id="basicpill-firstname-input3"
//                             placeholder="Enter Coupon Code"
//                             required
//                             name="couponCode"
//                             value={form.couponCode}
//                             onChange={e => {
//                               handleChange(e)
//                             }}
//                           />
//                         </div>
//                       </Col>
//                       {form.couponType == "FirstOrder" ? (
//                         ""
//                       ) : (
//                         <Col md={4}>
//                           <div className="mb-3">
//                             <Label for="basicpill-firstname-input2">
//                               Limit For Same User
//                               <span className="text-danger">*</span>
//                             </Label>
//                             <Input
//                               type="number"
//                               className="form-control"
//                               id="basicpill-firstname-input2"
//                               placeholder="Enter Ex : 10"
//                               required
//                               name="limitForSameUser"
//                               value={form.limitForSameUser}
//                               onChange={e => {
//                                 handleChange(e)
//                               }}
//                             />
//                           </div>
//                         </Col>
//                       )}
//                       {form.couponType == "FreeDelivery" ? (
//                         ""
//                       ) : (
//                         <>
//                           <Col md={4}>
//                             <div className="mb-3">
//                               <Label for="basicpill-firstname-input1">
//                                 Coupon Code Type
//                                 <span className="text-danger">*</span>
//                               </Label>
//                               <select
//                                 name="couponCodeType"
//                                 value={form.couponCodeType}
//                                 onChange={e => {
//                                   handleChange(e)
//                                 }}
//                                 required
//                                 className="form-select"
//                               >
//                                 <option value="">Select</option>
//                                 <option value="Price">Price</option>
//                                 <option value="Percentage">Percentage</option>
//                               </select>
//                             </div>
//                           </Col>
//                           <Col md={4}>
//                             {form.couponCodeType == "Percentage" ? (
//                               <div className="mb-3">
//                                 <Label for="basicpill-firstname-input2">
//                                   Percentage %
//                                   <span className="text-danger">*</span>
//                                 </Label>
//                                 <Input
//                                   type="number"
//                                   className="form-control"
//                                   id="basicpill-firstname-input2"
//                                   placeholder="Enter Percentage"
//                                   required
//                                   name="percentage"
//                                   value={form.percentage}
//                                   onChange={e => {
//                                     handleChange(e)
//                                   }}
//                                 />
//                               </div>
//                             ) : (
//                               <div className="mb-3">
//                                 <Label for="basicpill-firstname-input2">
//                                   Price <span className="text-danger">*</span>
//                                 </Label>
//                                 <Input
//                                   type="number"
//                                   className="form-control"
//                                   id="basicpill-firstname-input2"
//                                   placeholder="Enter Price "
//                                   required
//                                   name="price"
//                                   value={form.price}
//                                   onChange={e => {
//                                     handleChange(e)
//                                   }}
//                                 />
//                               </div>
//                             )}
//                           </Col>
//                         </>
//                       )}
//                       <Col md={4}>
//                         <div className="mb-3">
//                           <Label for="basicpill-firstname-input2">
//                             Minimum Purchase
//                             <span className="text-danger">*</span>
//                           </Label>
//                           <Input
//                             type="number"
//                             className="form-control"
//                             id="basicpill-firstname-input2"
//                             placeholder="Enter Minimum Purchase"
//                             required
//                             name="minimunPurchase"
//                             value={form.minimunPurchase}
//                             onChange={e => {
//                               handleChange(e)
//                             }}
//                           />
//                         </div>
//                       </Col>
//                       {form.couponType == "FreeDelivery" ? (
//                         ""
//                       ) : (
//                         <>
//                           <Col md={4}>
//                             <div className="mb-3">
//                               <Label for="basicpill-firstname-input2">
//                                 Maximum Discount
//                                 <span className="text-danger">*</span>
//                               </Label>
//                               <Input
//                                 type="number"
//                                 className="form-control"
//                                 id="basicpill-firstname-input2"
//                                 placeholder="Enter Maximum Discount"
//                                 required
//                                 name="maximumDiscount"
//                                 value={form.maximumDiscount}
//                                 onChange={e => {
//                                   handleChange(e)
//                                 }}
//                               />
//                             </div>
//                           </Col>
//                         </>
//                       )}
//                       <Col md={4}>
//                         <div className="mb-3">
//                           <Label for="basicpill-firstname-input2">
//                             From Date <span className="text-danger">*</span>
//                           </Label>
//                           <Input
//                             type="date"
//                             className="form-control"
//                             id="basicpill-firstname-input2"
//                             required
//                             name="fromDate"
//                             min={new Date().toISOString().split("T")[0]}
//                             value={form.fromDate}
//                             onChange={e => {
//                               handleChangedates(e)
//                             }}
//                           />
//                         </div>
//                       </Col>
//                       <Col md={4}>
//                         <div className="mb-3">
//                           <Label for="basicpill-firstname-input2">
//                             Expire Date <span className="text-danger">*</span>
//                           </Label>
//                           <Input
//                             type="date"
//                             className="form-control"
//                             id="basicpill-firstname-input2"
//                             placeholder="Enter  Version"
//                             required
//                             name="toDate"
//                             min={dds}
//                             value={form.toDate}
//                             onChange={e => {
//                               handleChange(e)
//                             }}
//                           />
//                         </div>
//                       </Col>
//                       {form.couponType == "CustomerWise" ? (
//                         <Col md={4}>
//                           <div className="mb-3">
//                             <Label for="basicpill-firstname-input1">
//                               Customer id / Name
//                               <span className="text-danger">*</span>
//                             </Label>
//                             <Select
//                               options={Users}
//                               placeholder="Select Customer id / Name"
//                               value={selectedOptions1}
//                               onChange={HealthStandard}
//                               isSearchable={true}
//                               isMulti
//                             />
//                           </div>
//                         </Col>
//                       ) : (
//                         ""
//                       )}
//                     </Row>
//                     <div style={{ float: "right" }}>
//                       <Button className="m-1" color="primary" type="submit">
//                         Submit <i className="fas fa-check-circle"></i>
//                       </Button>
//                     </div>
//                   </Form>
//                 </CardBody>
//               </Card>
//             </Col>
//             <Col md={12}>
//               <Card>
//                 <CardHeader className="bg-white">
//                   <CardTitle>Coupons List</CardTitle>
//                 </CardHeader>
//                 {isLoading == true ? (
//                   <>
//                     <div
//                       style={{ zIndex: "9999999999999", height: "420px" }}
//                       className="text-center mt-5 pt-5"
//                     >
//                       <img src={gig} height="140px"></img>
//                       <div>Loading......</div>
//                     </div>
//                   </>
//                 ) : (
//                   <>
//                     <CardBody>
//                       <div>
//                         <div className="table-responsive">
//                           <div style={{ float: "right" }}>
//                             <Input
//                               type="search"
//                               className="form-control"
//                               placeholder="Search.."
//                               value={search.search}
//                               onChange={searchAll}
//                               name="search"
//                             />
//                           </div>
//                           <Table className="table table-bordered mb-4 mt-5">
//                             <thead>
//                               <tr>
//                                 <th>S.No</th>
//                                 <th>Coupon Type</th>
//                                 <th>Title </th>
//                                 <th>Coupon Code </th>
//                                 <th>Limit For Same User</th>
//                                 <th>Coupon Code Type</th>
//                                 <th>Amount </th>
//                                 <th>Minimum Purchase </th>
//                                 <th>Maximum Discount </th>
//                                 <th>Users </th>
//                                 <th>From Date </th>
//                                 <th>Expire Date </th>
//                                 <th>Action</th>
//                               </tr>
//                             </thead>
//                             <tbody>
//                               {lists.map((data, key) => (
//                                 <tr key={key}>
//                                   <th>{(pageNumber - 1) * 5 + key + 6}</th>
//                                   <td>{data.couponType}</td>
//                                   <td>{data.title}</td>
//                                   <td>{data.couponCode}</td>
//                                   <td>{data.limitForSameUser}</td>
//                                   <td>{data.couponCodeType}</td>
//                                   <td>
//                                     {data.price == "" ? (
//                                       <>{data.percentage}%</>
//                                     ) : (
//                                       <>{data.price}</>
//                                     )}
//                                   </td>
//                                   <td>{data.minimunPurchase}</td>
//                                   <td>{data.maximumDiscount}</td>
//                                   <td className="text-center">
//                                     {data.couponType == "CustomerWise" ? (
//                                       <>
//                                         <Button
//                                           size="sm"
//                                           className="m-1"
//                                           outline
//                                           color="warning"
//                                           onClick={() => {
//                                             getinc(data)
//                                           }}
//                                         >
//                                           View
//                                         </Button>
//                                       </>
//                                     ) : (
//                                       <>-</>
//                                     )}
//                                   </td>
//                                   <td>{data.fromDate}</td>
//                                   <td>{data.toDate}</td>
//                                   <td>
//                                     <Button
//                                       onClick={() => {
//                                         getpopup(data)
//                                       }}
//                                       size="sm"
//                                       className="m-1"
//                                       color="success"
//                                     >
//                                       <div className="d-flex">
//                                         <i className="bx bx-edit px-1"></i>
//                                         <small> Edit </small>
//                                       </div>
//                                     </Button>
//                                     <Button
//                                       onClick={() => {
//                                         manageDelete(data)
//                                       }}
//                                       size="sm"
//                                       className="m-1"
//                                       color="danger"
//                                     >
//                                       <div className="d-flex">
//                                         <i className="bx bx-trash px-1"></i>
//                                         <small> Delete </small>
//                                       </div>
//                                     </Button>
//                                   </td>
//                                 </tr>
//                               ))}
//                             </tbody>
//                           </Table>
//                           <div className="mt-3" style={{ float: "right" }}>
//                             <ReactPaginate
//                               previousLabel={"Previous"}
//                               nextLabel={"Next"}
//                               pageCount={pageCount}
//                               onPageChange={changePage}
//                               containerClassName={"pagination"}
//                               previousLinkClassName={"previousBttn"}
//                               nextLinkClassName={"nextBttn"}
//                               disabledClassName={"disabled"}
//                               activeClassName={"active"}
//                               total={lists.length}
//                             />
//                           </div>
//                         </div>
//                       </div>
//                     </CardBody>
//                   </>
//                 )}
//               </Card>
//             </Col>
//           </Row>
//         </Container>
//         <ToastContainer />
//         <Modal
//           isOpen={modal_small}
//           toggle={() => {
//             tog_small()
//           }}
//           size="lg"
//         >
//           <div className="modal-header">
//             <h5 className="modal-title mt-0" id="mySmallModalLabel">
//               Edit Coupon Details
//             </h5>
//             <button
//               onClick={() => {
//                 setmodal_small(false)
//               }}
//               type="button"
//               className="close"
//               data-dismiss="modal"
//               aria-label="Close"
//             >
//               <span aria-hidden="true">&times;</span>
//             </button>
//           </div>
//           <div className="modal-body">
//             <Form
//               onSubmit={e => {
//                 handleSubmit1(e)
//               }}
//             >
//               <Row>
//                 <Col md={6}>
//                   <div className="mb-3">
//                     <Label for="basicpill-firstname-input1">
//                       Coupon Type
//                       <span className="text-danger">*</span>
//                     </Label>
//                     <select
//                       name="couponType"
//                       value={form1.couponType}
//                       onChange={e => {
//                         handleChange1(e)
//                       }}
//                       required
//                       className="form-select"
//                     >
//                       <option value="">Select</option>
//                       <option value="Default">Default</option>
//                       <option value="FirstOrder">First Order</option>
//                     </select>
//                   </div>
//                 </Col>
//                 <Col md={6}>
//                   <div className="mb-3">
//                     <Label for="basicpill-firstname-input2">
//                       Title <span className="text-danger">*</span>
//                     </Label>
//                     <Input
//                       type="text"
//                       className="form-control"
//                       id="basicpill-firstname-input2"
//                       placeholder="Enter Title"
//                       required
//                       name="title"
//                       value={form1.title}
//                       onChange={e => {
//                         handleChange1(e)
//                       }}
//                     />
//                   </div>
//                 </Col>
//                 <Col md={6}>
//                   <div className="mb-3">
//                     <Label for="basicpill-firstname-input3">
//                       Coupon Code <span className="text-danger">*</span>
//                     </Label>
//                     <Input
//                       type="text"
//                       className="form-control"
//                       id="basicpill-firstname-input3"
//                       placeholder="Enter Coupon Code"
//                       required
//                       name="couponCode"
//                       value={form1.couponCode}
//                       onChange={e => {
//                         handleChange1(e)
//                       }}
//                     />
//                   </div>
//                 </Col>
//                 {form1.couponType == "FirstOrder" ? (
//                   ""
//                 ) : (
//                   <Col md={6}>
//                     <div className="mb-3">
//                       <Label for="basicpill-firstname-input2">
//                         Limit For Same User
//                         <span className="text-danger">*</span>
//                       </Label>
//                       <Input
//                         type="text"
//                         className="form-control"
//                         id="basicpill-firstname-input2"
//                         placeholder="Enter Ex : 10"
//                         required
//                         name="limitForSameUser"
//                         value={form1.limitForSameUser}
//                         onChange={e => {
//                           handleChange1(e)
//                         }}
//                       />
//                     </div>
//                   </Col>
//                 )}
//                 {form1.couponType == "FreeDelivery" ? (
//                   ""
//                 ) : (
//                   <>
//                     <Col md={6}>
//                       <div className="mb-3">
//                         <Label for="basicpill-firstname-input1">
//                           Coupon Code Type
//                           <span className="text-danger">*</span>
//                         </Label>
//                         <select
//                           name="couponCodeType"
//                           value={form1.couponCodeType}
//                           onChange={e => {
//                             handleChange1(e)
//                           }}
//                           required
//                           className="form-select"
//                         >
//                           <option value="">Select</option>
//                           <option value="Price">Price</option>
//                           <option value="Percentage">Percentage</option>
//                         </select>
//                       </div>
//                     </Col>
//                     <Col md={6}>
//                       {form1.couponCodeType == "Percentage" ? (
//                         <div className="mb-3">
//                           <Label for="basicpill-firstname-input2">
//                             Percentage % <span className="text-danger">*</span>
//                           </Label>
//                           <Input
//                             type="number"
//                             className="form-control"
//                             id="basicpill-firstname-input2"
//                             placeholder="Enter Percentage"
//                             required
//                             name="percentage"
//                             value={form1.percentage}
//                             onChange={e => {
//                               handleChange1(e)
//                             }}
//                           />
//                         </div>
//                       ) : (
//                         <div className="mb-3">
//                           <Label for="basicpill-firstname-input2">
//                             Price <span className="text-danger">*</span>
//                           </Label>
//                           <Input
//                             type="number"
//                             className="form-control"
//                             id="basicpill-firstname-input2"
//                             placeholder="Enter Price "
//                             required
//                             name="price"
//                             value={form1.price}
//                             onChange={e => {
//                               handleChange1(e)
//                             }}
//                           />
//                         </div>
//                       )}
//                     </Col>
//                   </>
//                 )}
//                 <Col md={6}>
//                   <div className="mb-3">
//                     <Label for="basicpill-firstname-input2">
//                       Minimum Purchase <span className="text-danger">*</span>
//                     </Label>
//                     <Input
//                       type="number"
//                       className="form-control"
//                       id="basicpill-firstname-input2"
//                       placeholder="Enter Minimum Purchase"
//                       required
//                       name="minimunPurchase"
//                       value={form1.minimunPurchase}
//                       onChange={e => {
//                         handleChange1(e)
//                       }}
//                     />
//                   </div>
//                 </Col>
//                 {form1.couponType == "FreeDelivery" ? (
//                   ""
//                 ) : (
//                   <>
//                     <Col md={6}>
//                       <div className="mb-3">
//                         <Label for="basicpill-firstname-input2">
//                           Maximum Discount
//                           <span className="text-danger">*</span>
//                         </Label>
//                         <Input
//                           type="number"
//                           className="form-control"
//                           id="basicpill-firstname-input2"
//                           placeholder="Enter Maximum Discount"
//                           required
//                           name="maximumDiscount"
//                           value={form1.maximumDiscount}
//                           onChange={e => {
//                             handleChange1(e)
//                           }}
//                         />
//                       </div>
//                     </Col>
//                   </>
//                 )}
//                 <Col md={6}>
//                   <div className="mb-3">
//                     <Label for="basicpill-firstname-input2">
//                       From Date <span className="text-danger">*</span>
//                     </Label>
//                     <Input
//                       type="date"
//                       className="form-control"
//                       id="basicpill-firstname-input2"
//                       required
//                       name="fromDate"
//                       min={new Date().toISOString().split("T")[0]}
//                       value={form1.fromDate}
//                       onChange={e => {
//                         handleChangedates1(e)
//                       }}
//                     />
//                   </div>
//                 </Col>
//                 <Col md={6}>
//                   <div className="mb-3">
//                     <Label for="basicpill-firstname-input2">
//                       Expire Date <span className="text-danger">*</span>
//                     </Label>
//                     <Input
//                       type="date"
//                       className="form-control"
//                       id="basicpill-firstname-input2"
//                       placeholder="Enter  Version"
//                       required
//                       name="toDate"
//                       min={dds1}
//                       value={form1.toDate}
//                       onChange={e => {
//                         handleChange1(e)
//                       }}
//                     />
//                   </div>
//                 </Col>
//                 {form1.couponType == "CustomerWise" ? (
//                   <Col md={6}>
//                     <div className="mb-3">
//                       <Label for="basicpill-firstname-input1">
//                         Customer id / Name
//                         <span className="text-danger">*</span>
//                       </Label>
//                       <Select
//                         options={Users}
//                         placeholder="Select Customer id / Name"
//                         value={selectedOptions2}
//                         onChange={HealthStandard2}
//                         isSearchable={true}
//                         isMulti
//                       />
//                     </div>
//                   </Col>
//                 ) : (
//                   ""
//                 )}
//               </Row>
//               <div style={{ float: "right" }}>
//                 <Button
//                   onClick={() => {
//                     setmodal_small(false)
//                   }}
//                   color="danger"
//                   type="button"
//                 >
//                   Cancel <i className="fas fa-times-circle"></i>
//                 </Button>
//                 <Button className="m-1" color="primary" type="submit">
//                   Submit <i className="fas fa-check-circle"></i>
//                 </Button>
//               </div>
//             </Form>
//           </div>
//         </Modal>
//         <Modal
//           size="lg"
//           isOpen={ins}
//           toggle={() => {
//             inst()
//           }}
//           centered
//         >
//           <div className="modal-header">
//             <h5 className="modal-title mt-0" id="mySmallModalLabel">
//               View Users :-
//             </h5>
//             <button
//               onClick={() => {
//                 setins(false)
//               }}
//               type="button"
//               className="close"
//               data-dismiss="modal"
//               aria-label="Close"
//             >
//               <span aria-hidden="true">&times;</span>
//             </button>
//           </div>
//           <div className="modal-body">
//             {Instructions.map((data, i) => (
//               <>
//                 <p key={i} className="pt-2">
//                   <i className="bx bxs-send" style={{ color: "#B30706" }}></i>
//                   {data.label}
//                 </p>
//                 <hr></hr>
//               </>
//             ))}
//           </div>
//         </Modal>
//       </div>
//     </React.Fragment>
//   )
// }

// export default Coupons

import React, { useEffect, useState } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Modal,
} from "reactstrap"
import { ToastContainer, toast } from "react-toastify"
import axios from "axios"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ReactPaginate from "react-paginate"
import { URLS } from "../../Url"

const Coupons = () => {
  const [modal_small, setmodal_small] = useState(false)

  function tog_small() {
    setmodal_small(!modal_small)
    removeBodyCss()
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [coup, setcoup] = useState([])
  const [form, setform] = useState([])
  const [form1, setform1] = useState([])

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }
  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  const getAllbcoupons = () => {
    var token = datas
    axios
      .post(
        URLS.GetCoupon,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setcoup(res.data.cpns)
      })
  }

  const addcoupons = () => {
    var token = datas

    const dataArray = {
      title: form.title,
      couponCode: form.couponCode,
      couponCodeType: form.couponCodeType,
      price: form.price,
      description: form.description,
      fromDate: form.fromDate,
      toDate: form.toDate,
    }

    axios
      .post(URLS.AddCoupon, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getAllbcoupons()
            clearForm()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const getpopup = data => {
    setform1(data)
    tog_small()
  }

  const editcoupon = () => {
    var token = datas
    var formid = form1._id

    const dataArray = {
      title: form1.title,
      couponCode: form1.couponCode,
      couponCodeType: form1.couponCodeType,
      price: form1.price,
      description: form1.description,
      fromDate: form1.fromDate,
      toDate: form1.toDate,
    }

    axios
      .put(URLS.UpdateCoupon + "/" + formid, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getAllbcoupons()
            setmodal_small(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const handleSubmit = e => {
    e.preventDefault()
    addcoupons()
  }

  const handleSubmit1 = e => {
    e.preventDefault()
    editcoupon()
  }

  const deletebenners = data => {
    var token = datas
    var remid = data._id
    axios
      .delete(URLS.InActiveCoupon + "/" + remid, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getAllbcoupons()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      deletebenners(data)
    }
  }

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = coup.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(coup.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  useEffect(() => {
    getAllbcoupons()
  }, [])

  const clearForm = () => {
    setform({
      title: "",
      couponCode: "",
      couponCodeType: "",
      price: "",
      description: "",
      fromDate: "",
      toDate: "",
      status: "",
    })
  }

  const [search, setsearch] = useState([])

  const searchAll = e => {
    let myUser = { ...search }
    myUser[e.target.name] = e.target.value
    setsearch(myUser)

    var token = datas
    axios
      .post(
        URLS.GetCouponSearch + `${e.target.value}`,
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setcoup(res.data.cpns)
      })
  }

  const [dds, setdds] = useState([])

  const handleChangedates = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)

    const tomorrow = new Date(e.target.value)
    tomorrow.setDate(tomorrow.getDate() + 1)
    setdds(tomorrow.toISOString().split("T")[0])
  }

  const [dds1, setdds1] = useState([])

  const handleChangedates1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)

    const tomorrow = new Date(e.target.value)
    tomorrow.setDate(tomorrow.getDate() + 1)
    setdds1(tomorrow.toISOString().split("T")[0])
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Go Cut Admin" breadcrumbItem="Coupons" />
          <Row>
            <Col md={4}>
              <Card>
                <CardHeader className="bg-white">
                  <CardTitle>Add Coupon</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      handleSubmit(e)
                    }}
                  >
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input2">
                        Title <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input2"
                        placeholder="Enter Title"
                        required
                        name="title"
                        value={form.title}
                        onChange={e => {
                          handleChange(e)
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input3">
                        Coupon Code <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input3"
                        placeholder="Enter Coupon Code"
                        required
                        name="couponCode"
                        value={form.couponCode}
                        onChange={e => {
                          handleChange(e)
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Coupon Code Type<span className="text-danger">*</span>
                      </Label>
                      <select
                        name="couponCodeType"
                        value={form.couponCodeType}
                        onChange={e => {
                          handleChange(e)
                        }}
                        required
                        className="form-select"
                      >
                        <option value="">Select</option>
                        <option value="Price">Price</option>
                        <option value="Percentage">Percentage</option>
                      </select>
                    </div>
                    {form.couponCodeType == "Percentage" ? (
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input2">
                          Percentage % <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="number"
                          className="form-control"
                          id="basicpill-firstname-input2"
                          placeholder="Enter Percentage"
                          required
                          name="price"
                          value={form.price}
                          onChange={e => {
                            handleChange(e)
                          }}
                        />
                      </div>
                    ) : (
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input2">
                          Price <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="number"
                          className="form-control"
                          id="basicpill-firstname-input2"
                          placeholder="Enter Price "
                          required
                          name="price"
                          value={form.price}
                          onChange={e => {
                            handleChange(e)
                          }}
                        />
                      </div>
                    )}
                    <div className="mb-3"></div>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input2">
                        From Date <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="date"
                        className="form-control"
                        id="basicpill-firstname-input2"
                        required
                        name="fromDate"
                        min={new Date().toISOString().split("T")[0]}
                        value={form.fromDate}
                        onChange={e => {
                          handleChangedates(e)
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input2">
                        Expire Date <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="date"
                        className="form-control"
                        id="basicpill-firstname-input2"
                        placeholder="Enter  Version"
                        required
                        name="toDate"
                        min={dds}
                        value={form.toDate}
                        onChange={e => {
                          handleChange(e)
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input2">
                        Description
                      </Label>
                      <textarea
                        type="date"
                        className="form-control"
                        id="basicpill-firstname-input2"
                        placeholder="Enter Description"
                        name="description"
                        value={form.description}
                        onChange={e => {
                          handleChange(e)
                        }}
                        required
                      />
                    </div>
                    <div style={{ float: "right" }}>
                      <Button className="m-1" color="primary" type="submit">
                        Submit <i className="fas fa-check-circle"></i>
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>

            <Col md={8}>
              <Card>
                <CardHeader className="bg-white">
                  <CardTitle>Coupons List</CardTitle>
                </CardHeader>
                <CardBody>
                  <div>
                    <div className="table-responsive">
                      <div style={{ float: "right" }}>
                        <Input
                          type="search"
                          className="form-control"
                          placeholder="Search.."
                          value={search.search}
                          onChange={searchAll}
                          name="search"
                        />
                      </div>
                      <Table className="table table-bordered mb-4 mt-5">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Title</th>
                            <th>Coupon Code</th>
                            <th>Coupon Type</th>
                            <th>Coupon Price</th>
                            <th>From Date</th>
                            <th>To Date</th>
                            <th>Description</th>
                            <th style={{ width: "100px" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {lists.map((data, key) => (
                            <tr key={key}>
                              <th>{(pageNumber - 1) * 5 + key + 6}</th>
                              <td>{data.title}</td>
                              <td>{data.couponCode}</td>
                              <td>{data.couponCodeType}</td>
                              <td>
                                {data.couponCodeType == "Percentage" ? (
                                  <>{data.price}%</>
                                ) : (
                                  <>{data.price} </>
                                )}
                              </td>
                              <td>{data.fromDate}</td>
                              <td>{data.toDate}</td>
                              <td>{data.description}</td>

                              <td>
                                <Button
                                  onClick={() => {
                                    getpopup(data)
                                  }}
                                  className="mr-2"
                                  style={{ padding: "6px", margin: "3px" }}
                                  color="success"
                                  outline
                                >
                                  <i className="bx bx-edit "></i>
                                </Button>

                                <Button
                                  onClick={() => {
                                    manageDelete(data)
                                  }}
                                  style={{ padding: "6px", margin: "3px" }}
                                  color="danger"
                                  outline
                                >
                                  <i className="bx bx-trash"></i>
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <div className="mt-3" style={{ float: "right" }}>
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          pageCount={pageCount}
                          onPageChange={changePage}
                          containerClassName={"pagination"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"disabled"}
                          activeClassName={"active"}
                          total={lists.length}
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
        <Modal
          size="lg"
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Edit Coupon
            </h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                handleSubmit1(e)
              }}
            >
              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input2">
                      Title <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input2"
                      placeholder="Enter Title"
                      required
                      name="title"
                      value={form1.title}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Coupon Code Type<span className="text-danger">*</span>
                    </Label>
                    <select
                      name="couponCodeType"
                      value={form1.couponCodeType}
                      onChange={e => {
                        handleChange1(e)
                      }}
                      className="form-select"
                    >
                      <option value="Price">Price</option>
                      <option value="Percentage">Percentage</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input2">
                      From Date <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="date"
                      className="form-control"
                      id="basicpill-firstname-input2"
                      required
                      name="fromDate"
                      value={form1.fromDate}
                      onChange={e => {
                        handleChangedates1(e)
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input2">Description</Label>
                    <textarea
                      type="date"
                      className="form-control"
                      id="basicpill-firstname-input2"
                      placeholder="Enter Description"
                      name="description"
                      value={form1.description}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input3">
                      Coupon Code <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input3"
                      placeholder="Enter Coupon Code"
                      required
                      name="couponCode"
                      value={form1.couponCode}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                  {form1.couponCodeType == "Percentage" ? (
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input2">
                        Percentage % <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input2"
                        placeholder="Enter Percentage"
                        required
                        name="price"
                        value={form1.price}
                        onChange={e => {
                          handleChange1(e)
                        }}
                      />
                    </div>
                  ) : (
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input2">
                        Price <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input2"
                        placeholder="Enter Price"
                        required
                        name="price"
                        value={form1.price}
                        onChange={e => {
                          handleChange1(e)
                        }}
                      />
                    </div>
                  )}
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input2">
                      Expire Date <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="date"
                      className="form-control"
                      id="basicpill-firstname-input2"
                      placeholder="Enter  Version"
                      required
                      name="toDate"
                      min={dds1}
                      value={form1.toDate}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="primary" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default Coupons
