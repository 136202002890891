import React, { useState, useEffect } from "react"
import {
  CardBody,
  Container,
  Row,
  Col,
  Card,
  Form,
  Input,
  Button,
  Table,
  Modal,
  Label,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { URLS } from "../../Url"

function Support() {
  const [modal_small, setmodal_small] = useState(false)
  const [banner, setbanner] = useState([])
  const [form1, setform1] = useState([])

  function tog_small() {
    setmodal_small(!modal_small)
  }

  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  useEffect(() => {
    GetAllBanners()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = banner.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(banner.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const EditBanner = () => {
    var token = datas
    var formid = form1._id
    const dataArray = { reply: form1.reply, status: form1.status }

    axios
      .put(URLS.UpdateVendorSupport + formid, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetAllBanners()
            setmodal_small(false)
            clearForm1()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const handleSubmit1 = e => {
    e.preventDefault()
    EditBanner()
  }

  const GetAllBanners = () => {
    var token = datas
    axios
      .post(
        URLS.GetVendorSupport,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setbanner(res.data.data)
      })
  }
  const clearForm1 = () => {
    setFiles1({
      status: "",
      reply: "",
    })
  }

  const getpopup = data => {
    setform1(data)
    tog_small()
  }

  const [search, setsearch] = useState([])

  const searchAll = e => {
    let myUser = { ...search }
    myUser[e.target.name] = e.target.value
    setsearch(myUser)

    var token = datas
    axios
      .post(
        URLS.GetVendorSupportSearch + `${e.target.value}`,
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setbanner(res.data.data)
      })
  }

  const [info, setinfo] = useState(false)

  const [Information, setInformation] = useState([])

  function infos() {
    setinfo(!info)
  }

  const getinfo = data => {
    setInformation(data.description)
    infos()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Go Cut" breadcrumbItem="Vendor Support" />
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <div>
                    <div className="table-responsive">
                      <div style={{ float: "right" }}>
                        <Input
                          type="search"
                          className="form-control"
                          placeholder="Search.."
                          value={search.search}
                          onChange={searchAll}
                          name="search"
                        />
                      </div>
                      <Table className="table table-bordered mb-4 mt-5">
                        <thead>
                          <tr className="text-center">
                            <th>S.No</th>
                            <th>Ticket Id</th>
                            <th>Date </th>
                            <th>Time</th>
                            <th>Name</th>
                            <th>Phone</th>
                            <th>Title</th>
                            <th>Screen Shot</th>
                            <th>Description</th>
                            <th>Reply</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {lists.map((data, key) => (
                            <tr key={key} className="text-center">
                              <th>{(pageNumber - 1) * 5 + key + 6}</th>
                              <td>{data.ticketId}</td>
                              <td>{data.date}</td>
                              <td>{data.time}</td>
                              <td>{data.vendorName}</td>
                              <td>{data.vendorMobile}</td>
                              <td>{data.reason} </td>
                              <td>
                                <a
                                  href={URLS.Base + data.image}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <img
                                    style={{ width: "100px", height: "80px" }}
                                    src={URLS.Base + data.image}
                                  />
                                </a>
                              </td>
                              <td>
                                <Button
                                  outline
                                  className="btn-sm"
                                  color="warning"
                                  onClick={() => {
                                    getinfo(data)
                                  }}
                                >
                                  <div className="d-flex">
                                    <i className="bx bxs-file px-1"></i>
                                    <small> View </small>
                                  </div>
                                </Button>
                              </td>
                              <td>
                                {data.reply == "" ? <>-</> : <>{data.reply}</>}
                              </td>
                              <td>
                                {data.status == "pending" ? (
                                  <>
                                    <span className="badge bg-warning">
                                      {data.status}
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <span className="badge bg-success">
                                      {data.status}
                                    </span>
                                  </>
                                )}
                              </td>
                              <td>
                                {data.status == "pending" ? (
                                  <Button
                                    onClick={() => {
                                      getpopup(data)
                                    }}
                                    size="sm"
                                    className="m-1"
                                    color="success"
                                  >
                                    <div className="d-flex">
                                      <i className="bx bx-edit px-1"></i>
                                      <small> Update </small>
                                    </div>
                                  </Button>
                                ) : (
                                  "-"
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <div className="mt-3" style={{ float: "right" }}>
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          pageCount={pageCount}
                          onPageChange={changePage}
                          containerClassName={"pagination"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"disabled"}
                          activeClassName={"active"}
                          total={lists.length}
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal
          size="md"
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Support Status
            </h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                handleSubmit1(e)
              }}
            >
              <Col md={12}>
                <div className="mb-3">
                  <Label for="basicpill-firstname-input3">
                    Status <span className="text-danger">*</span>
                  </Label>

                  <select
                    className="form-select"
                    required
                    value={form1.status}
                    name="status"
                    onChange={e => {
                      handleChange1(e)
                    }}
                  >
                    <option value=""> Select </option>
                    <option value="solved">Solved</option>
                  </select>
                </div>
              </Col>
              <Col md={12}>
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Reply <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="textarea"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter Reply"
                    required
                    value={form1.reply}
                    name="reply"
                    onChange={e => {
                      handleChange1(e)
                    }}
                  />
                </div>
              </Col>
              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="primary" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
        <Modal
          size="md"
          isOpen={info}
          toggle={() => {
            infos()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              View Description :-
            </h5>
            <button
              onClick={() => {
                setinfo(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p className="pt-2">{Information}</p>
          </div>
        </Modal>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default Support
