import React, { useState, useEffect } from "react"
import {
  CardBody,
  Container,
  Row,
  Col,
  Card,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import classnames from "classnames"
import axios from "axios"
import { URLS } from "../../Url"

function HospitalPrice() {
  const history = useHistory()

  const [activeTab1, setactiveTab1] = useState("5")

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const hcuid = sessionStorage.getItem("Hospitalidss")

  // useEffect(() => {
  //   GetAllHomeTests()
  //   GetAllInvestigations()
  //   GetAlldoctors()
  //   GetAllService()
  //   GetOneHospital()
  // }, [])

  const [test, settest] = useState([])

  const GetAllHomeTests = () => {
    var token = datas
    axios
      .post(
        URLS.getaddAllhometest,
        { hocId: hcuid },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        settest(res.data.tests)
      })
  }

  const [Investigations, setInvestigations] = useState([])

  const GetAllInvestigations = () => {
    var token = datas
    axios
      .post(
        URLS.getaddAllInvestigations,
        { hocId: hcuid },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setInvestigations(res.data.investigations)
      })
  }

  const [doctor, setdoctor] = useState([])

  const GetAlldoctors = () => {
    var token = datas
    axios
      .post(
        URLS.GetAllDoctor,
        { hospitalId: hcuid },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setdoctor(res.data.dctr)
      })
  }

  const [Service, setService] = useState([])

  const GetAllService = () => {
    var token = datas
    axios
      .post(
        URLS.GetallHomeServiceslist,
        { hocId: hcuid },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setService(res.data.homeservice)
      })
  }

  const [forms, setforms] = useState([])

  const GetOneHospital = () => {
    const data = {
      _id: hcuid,
    }

    var token = datas
    axios
      .post(URLS.GetIdHospital, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setforms(res.data.hospital)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Go Cut" breadcrumbItem="Vendors" />
          <Row>
            {" "}
            <Col md={12}>
              <Button
                onClick={() => history.goBack()}
                className="mb-3  m-1 "
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i> Back
              </Button>
            </Col>{" "}
          </Row>
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <div className="mb-4">
                    <Nav pills className="navtab-bg nav-justified">
                      
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab1 === "5",
                          })}
                          onClick={() => {
                            toggle1("5")
                          }}
                        >
                          Pending Bookings (0)
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab1 === "6",
                          })}
                          onClick={() => {
                            toggle1("6")
                          }}
                        >
                          Completed Bookings (0)
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab1 === "7",
                          })}
                          onClick={() => {
                            toggle1("7")
                          }}
                        >
                          Cancelled Bookings (0)
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab1 === "8",
                          })}
                          onClick={() => {
                            toggle1("8")
                          }}
                        >
                          Re-Schedule Bookings (0)
                        </NavLink>
                      </NavItem>

                    </Nav>
                  </div>

                  <TabContent activeTab={activeTab1} className=" text-muted">
                    <TabPane tabId="5">
                      <h5
                        className="mb-3 text-primary "
                        style={{ textDecoration: "underline" }}
                      >
                        Pending Bookings
                      </h5>
                      <div>
                        <div>
                          <div className="table-responsive">
                            <Table className="table table-bordered mb-2 mt-3">
                              <thead>
                                <tr>
                                  <th>S.No</th>
                                  <th>Salon Name </th>
                                  <th>User Id</th>
                                  <th>Booking Id</th>
                                  <th>User Name</th>
                                  <th>Contact No</th>
                                  <th>Services Details</th>
                                  <th>Date</th>
                                  <th>Time</th>
                                  <th>Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {/* {test.map((data, key) => (
                                  <tr key={key} className="text-center">
                                    <td>{key + 1}</td>
                                    <td>
                                      <img
                                        src={URLS.Base + data.testImage}
                                        width="80px"
                                      ></img>
                                    </td>
                                    <td>{data.testid}</td>
                                    <td>{data.hocTestid}</td>
                                    <td>{data.title}</td>
                                    <td>{data.nabhPrice}</td>
                                    <td>{data.nonNabhPrice}</td>
                                    <td>{data.hocTestPrice}</td>
                                    <td>{data.vacutainerColor}</td>
                                    <td className="text-start">
                                      {data.instructions.map((data, i) => (
                                        <>
                                          <p key={i}>{data.instructions} </p>
                                        </>
                                      ))}
                                    </td>
                                    <td>{data.information}..</td>

                                    <td className="text-start">
                                      <span className="badge bg-success ">
                                        {data.status}
                                      </span>
                                    </td>
                                  </tr>
                                ))} */}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="6">
                      <h5
                        className="mb-3 text-primary "
                        style={{ textDecoration: "underline" }}
                      >
                        Completed Bookings
                      </h5>

                      <div className="table-responsive">
                        <Table className="table table-bordered mb-2 mt-3">
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Salon Name </th>
                              <th>User Id</th>
                              <th>Booking Id</th>
                              <th>User Name</th>
                              <th>Contact No</th>
                              <th>Services Details</th>
                              <th>Date</th>
                              <th>Time</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* {Investigations.map((data, key) => (
                              <tr key={key} className="text-center">
                                <td>{key + 1}</td>
                                <td>
                                  <img
                                    src={URLS.Base + data.testImage}
                                    width="80px"
                                  ></img>
                                </td>
                                <td>{data.testid}</td>
                                <td>{data.hocTestid}</td>
                                <td>{data.title}</td>
                                <td>{data.price}</td>
                                <td>{data.roomLocationHoc}</td>
                                <td className="text-start">
                                  {data.instructions.map((data, i) => (
                                    <>
                                      <p key={i}>{data.instructions}</p>
                                    </>
                                  ))}
                                </td>

                                <td className="text-start">
                                  {data.information}..
                                </td>

                                <td>
                                  <span className="badge bg-success ">
                                    {data.status}
                                  </span>
                                </td>
                              </tr>
                            ))} */}
                          </tbody>
                        </Table>
                      </div>
                    </TabPane>
                    <TabPane tabId="7">
                      <h5
                        className="mb-3 text-primary "
                        style={{ textDecoration: "underline" }}
                      >
                        Cancelled Bookings
                      </h5>{" "}
                      <div className="table-responsive">
                        <Table className="table table-bordered mb-2 mt-3">
                          <thead>
                            <tr className="text-center">
                              <th>S.No</th>
                              <th>Salon Name </th>
                              <th>User Id</th>
                              <th>Booking Id</th>
                              <th>User Name</th>
                              <th>Contact No</th>
                              <th>Services Details</th>
                              <th>Date</th>
                              <th>Time</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* {doctor.map((data, key) => (
                              <tr key={key} className="text-center">
                                <td>{key + 1}</td>
                                <td>{data.doctorEmployId}</td>
                                <td>
                                  <img
                                    src={URLS.Base + data.employImagelogo}
                                    width="80px"
                                  ></img>
                                </td>
                                <td>
                                  {data.firstName}/{data.lastName}
                                </td>
                                <td>{data.phone}</td>
                                <td>{data.email}</td>
                                <td>{data.locationInHoc}</td>
                                <td>{data.tarrifPrice}</td>
                                <td>{data.speciality}</td>
                              </tr>
                            ))} */}
                          </tbody>
                        </Table>
                      </div>
                    </TabPane>
                    <TabPane tabId="8">
                      <h5
                        className="mb-3 text-primary "
                        style={{ textDecoration: "underline" }}
                      >
                        Re-Schedule Bookings
                      </h5>{" "}
                      <div>
                        <div>
                          <div className="table-responsive">
                            <Table className="table table-bordered mb-2 mt-3">
                              <thead>
                                <tr className="text-center">
                                  <th>S.No</th>
                                  <th>Salon Name </th>
                                  <th>User Id</th>
                                  <th>Booking Id</th>
                                  <th>User Name</th>
                                  <th>Contact No</th>
                                  <th>Services Details</th>
                                  <th>Date</th>
                                  <th>Time</th>
                                  <th>Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {/* {Service.map((data, key) => (
                                  <tr key={key} className="text-center">
                                    <td>{key + 1}</td>
                                    <td>
                                      <img
                                        src={URLS.Base + data.testImage}
                                        width="80px"
                                      ></img>
                                    </td>
                                    <td>{data.testid}</td>
                                    <td>{data.hocTestid}</td>
                                    <td>{data.title}</td>
                                    <td>{data.hocTestPrice}</td>
                                    <td>{data.information}</td>
                                    <td>
                                      {data.instructions.map((data, i) => (
                                        <>
                                          <li key={i} className="text-start">
                                            {data.instructions}
                                          </li>
                                        </>
                                      ))}
                                    </td>

                                    <td>
                                      <span className="badge bg-success ">
                                        {data.status}
                                      </span>
                                    </td>
                                  </tr>
                                ))} */}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </div>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default HospitalPrice
