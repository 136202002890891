import React, { useEffect, useState } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  Button,
  Modal,
  Form,
  Table,
  Label,
  Input,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import gig from "../../assets/images/logos.png"
import { URLS } from "../../Url"
import axios from "axios"

function DigitalBrochure() {
  const [isLoading, setIsLoading] = useState(false)

  const [form, setform] = useState([])

  const [forms, setforms] = useState([])

  const handlechange = e => {
    const myform = { ...forms }
    myform[e.target.name] = e.target.value
    setforms(myform)
  }

  useEffect(() => {
    GetAllBroucher()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const GetAllBroucher = () => {
    var token = datas

    axios
      .post(
        URLS.Getcharges,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setform(res.data.contactus)
        setforms(res.data.contactus)
        setIsLoading(false)
      })
  }
  const [modal_small, setmodal_small] = useState(false)
  function tog_small() {
    setmodal_small(!modal_small)
  }

  const getpopup1 = () => {
    tog_small()
  }

  const submibooking = e => {
    e.preventDefault()

    changstatus()
  }

  const changstatus = () => {
    var token = datas
    const Data = {
      gocutCommission: forms.gocutCommission,
      seatLimit: forms.seatLimit,
      // gst: forms.gst,
    }

    axios
      .put(URLS.Updatecharges, Data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            setmodal_small(false)
            GetAllBroucher()
            setIsLoading(true)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
            setIsLoading(false)
          }
        }
      )
  }

  var gets = localStorage.getItem("authUser")


  const [userdata, setUserdata] = useState({
    name: "shiva",
    phone: 789798789,
    email: "shiva@gmail.com",
    products: {
      name: "mobile",
      price: 30000,
      brand: "iphone",
    },
  });

  const handleChange = (e, parentKey) => {
    const { name, value } = e.target;

    setUserdata((prevData) => {
      if (parentKey) {
        // Update nested object
        return {
          ...prevData,
          [parentKey]: {
            ...prevData[parentKey],
            [name]: value,
          },
        };
      } else {
        // Update top-level key
        return {
          ...prevData,
          [name]: value,
        };
      }
    });
  };
  

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Go Cut" breadcrumbItem="Charges " />

          {/* <Row>
            <Col md={12}>
              <Card>
                <CardHeader className="bg-white"></CardHeader>
                {isLoading == true ? (
                  <>
                    <div
                      style={{ zIndex: "9999999999999", height: "420px" }}
                      className="text-center mt-5 pt-5"
                    >
                      <img src={gig} height="140px"></img>
                      <div>Loading......</div>
                    </div>
                  </>
                ) : (
                  <>
                    <CardBody>
                      <div style={{ float: "right" }}>
                        <Button
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Edit Booking"
                          onClick={() => {
                            getpopup1(form)
                          }}
                          className="mr-5 mb-1 m-1 "
                          color="success"
                          outline
                        >
                          <i className="bx bx-edit text-dark "></i>
                          <span>Edit</span>
                        </Button>
                      </div>

                      <Row>
                        <div className="m-5">
                          <div className="table-rep-plugin mt-4 table-responsive">
                            <Table hover className="table table-bordered mb-4">
                              <thead>
                                <tr className="text-center">
                                  <th>Gocut commission</th>
                                  <td>{form.gocutCommission} %</td>
                                </tr>
                                <tr className="text-center">
                                  <th>Max seat limit</th>
                                  <td>{form.seatLimit}</td>
                                </tr>
                              </thead>
                            </Table>
                          </div>
                        </div>
                      </Row>
                    </CardBody>
                  </>
                )}
              </Card>
            </Col>
          </Row> */}

<div>
      <h1>User Details</h1>
      <form>
        {Object.entries(userdata).map(([key, value]) =>
          typeof value === "object" ? (
            <div key={key}>
              <h3>{key}</h3>
              {Object.entries(value).map(([subKey, subValue]) => (
                <div key={subKey}>
                  <label>
                    {subKey}:
                    <input
                      type="text"
                      name={subKey}
                      value={subValue}
                      onChange={(e) => handleChange(e, key)}
                    />
                  </label>
                </div>
              ))}
            </div>
          ) : (
            <div key={key}>
              <label>
                {key}:
                <input
                  type="text"
                  name={key}
                  value={value}
                  onChange={handleChange}
                />
              </label>
            </div>
          )
        )}
      </form>
      <pre>{JSON.stringify(userdata, null, 2)}</pre>
    </div>


          <Modal
            size="lg"
            isOpen={modal_small}
            toggle={() => {
              tog_small()
            }}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="mySmallModalLabel">
                Edit Charges
              </h5>
              <button
                onClick={() => {
                  setmodal_small(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Form
                onSubmit={e => {
                  submibooking(e)
                }}
              >
                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                      Gocut Commission
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="EnterTransaction Charges"
                        required
                        value={forms.gocutCommission}
                        name="gocutCommission"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                      <small className="text-danger">Enter your commission in percentage</small>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                      Seat Limit
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Seat Limit"
                        required
                        value={forms.seatLimit}
                        name="seatLimit"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                      <small className="text-danger">Enter maximum seat limit</small>
                    </div>
                  </Col>
                  {/* <Col md={6}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Gst Charges <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Gst Charges"
                        required
                        value={forms.gst}
                        name="gst"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col> */}
                </Row>
                <hr></hr>
                <div style={{ float: "right" }} className="m-2">
                  <Button className="m-1" color="primary" type="submit">
                    Submit <i className="fas fa-check-circle"></i>
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default DigitalBrochure
