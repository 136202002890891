import React, { useState } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
} from "reactstrap"
import axios from "axios"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import { URLS } from "../../Url"
import { useHistory } from "react-router-dom"

const Roles = () => {
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [ras, setras] = useState({
    Dashview: false,
    EmployeeManagmentView: false,

    departementView: false,
    departementAdd: false,

    departementeEdit: false,
    departementBlock: false,
    rolesAndPermissionView: false,
    rolesAndPermissionAdd: false,
    rolesAndPermissionEdit: false,

    staffView: false,
    staffAdd: false,
    staffEdit: false,
    staffBlock: false,

    UserListView: false,

    userListView: false,
    userListAdd: false,
    userListEdit: false,
    userListBlock: false,

    BookingManagmentView: false,

    paymentsView: false,
    paymentsEdit: false,

    BannerView: false,

    bannersView: false,
    bannersAdd: false,
    bannersEdit: false,
    bannersDelete: false,

    suBannersView: false,
    suBannersAdd: false,
    suBannersEdit: false,
    suBannersDelete: false,

    couponsView: false,
    couponsAdd: false,
    couponsEdit: false,

    notificationsView: false,
    notificationsAdd: false,
    notificationsDelete: false,

    SettingsView: false,

    settingsAdd: false,
    settingsEdit: false,
    settingsView: false,
    settingsDelete: false,
  })

  const handleChange1s = e => {
    const myUser = { ...ras }
    myUser[e.target.name] = e.target.checked
    setras(myUser)
  }

  const [form, setform] = useState([])

  const handleSubmit = e => {
    e.preventDefault()
    Addrole()
  }

  const check = {
    Dashview: ras.Dashview,

    EmployeeManagmentView: ras.EmployeeManagmentView,

    departementView: ras.departementView,
    departementAdd: ras.departementAdd,
    departementeEdit: ras.departementeEdit,
    departementBlock: ras.departementBlock,

    rolesAndPermissionView: ras.rolesAndPermissionView,
    rolesAndPermissionAdd: ras.rolesAndPermissionAdd,
    rolesAndPermissionEdit: ras.rolesAndPermissionEdit,

    staffView: ras.staffView,
    staffAdd: ras.staffAdd,
    staffEdit: ras.staffEdit,
    staffBlock: ras.staffBlock,

    UserListView: ras.UserListView,

    userListView: ras.userListView,
    userListAdd: ras.userListAdd,
    userListEdit: ras.userListEdit,
    userListBlock: ras.userListBlock,

    BookingManagmentView: ras.BookingManagmentView,

    paymentsView: ras.paymentsView,
    paymentsEdit: ras.paymentsEdit,

    BannerView: ras.BannerView,

    bannersView: ras.bannersView,
    bannersAdd: ras.bannersAdd,
    bannersEdit: ras.bannersEdit,
    bannersDelete: ras.bannersDelete,

    suBannersView: ras.suBannersView,
    suBannersAdd: ras.suBannersAdd,
    suBannersEdit: ras.suBannersEdit,
    suBannersDelete: ras.suBannersDelete,

    couponsView: ras.couponsView,
    couponsAdd: ras.couponsAdd,
    couponsEdit: ras.couponsEdit,

    notificationsView: ras.notificationsView,
    notificationsAdd: ras.notificationsAdd,
    notificationsDelete: ras.notificationsDelete,

    SettingsView: ras.SettingsView,

    settingsAdd: ras.settingsAdd,
    settingsEdit: ras.settingsEdit,
    settingsView: ras.settingsView,
    settingsDelete: ras.settingsDelete,
  }

  const Addrole = () => {
    const token = datas

    const data = {
      role: form.role,
      permissions: check,
    }
    axios
      .post(URLS.AddRole, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            history.push("/RolesPremissions")
            sessionStorage.setItem("tost", "Roles has been Added Successfully")
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const history = useHistory()

  function handleChange(e) {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
          <Breadcrumbs title="Go Cut Admin" breadcrumbItem="Add Roles" />
          <Row>
            <Col>
              <Button
                onClick={() => history.goBack()}
                className="mb-3  m-1 "
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i> Back
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card>
                <CardHeader className="bg-white mt-2">
                  <CardTitle>Role & Permissions</CardTitle>
                </CardHeader>

                <CardBody>
                  <Form
                    onSubmit={e => {
                      handleSubmit(e)
                    }}
                  >
                    <Row>
                      <Col md={4}>
                        <Label for="basicpill-firstname-input1">
                          Role <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter Role  Name"
                          required
                          value={form.role}
                          name="role"
                          pattern="^[a-zA-Z ]*$"
                          onChange={e => {
                            handleChange(e)
                          }}
                        />
                      </Col>
                    </Row>
                    <h5 className="mt-4 mb-3">Dashboard:</h5>
                    <Row className=" mt-3">
                      <Col md={2}>
                        <p className="">Dashboard: </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="Dashview"
                            defaultChecked={ras.Dashview}
                            value={ras.Dashview}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="read">
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <h5 className="mt-3 mb-3">Employee Managment:</h5>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Employee Managment View: </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="EmployeeManagmentView"
                            defaultChecked={ras.EmployeeManagmentView}
                            value={ras.EmployeeManagmentView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Department : </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="departementView"
                            defaultChecked={ras.departementView}
                            value={ras.departementView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="departementAdd"
                            defaultChecked={ras.departementAdd}
                            value={ras.departementAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empAdd">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="departementeEdit"
                            defaultChecked={ras.departementeEdit}
                            value={ras.departementeEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="departementDelete"
                            defaultChecked={ras.departementDelete}
                            value={ras.departementDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empl3">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Roles & Premissions : </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="rolesAndPermissionView"
                            defaultChecked={ras.rolesAndPermissionView}
                            value={ras.rolesAndPermissionView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="rolesAndPermissionAdd"
                            defaultChecked={ras.rolesAndPermissionAdd}
                            value={ras.rolesAndPermissionAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empAdd">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="rolesAndPermissionEdit"
                            defaultChecked={ras.rolesAndPermissionEdit}
                            value={ras.rolesAndPermissionEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Staff : </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="staffView"
                            defaultChecked={ras.staffView}
                            value={ras.staffView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="staffAdd"
                            defaultChecked={ras.staffAdd}
                            value={ras.staffAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empAdd">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="staffEdit"
                            defaultChecked={ras.staffEdit}
                            value={ras.staffEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="staffDelete"
                            defaultChecked={ras.staffDelete}
                            value={ras.staffDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empl3">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <h5 className="mt-3 mb-3">Services :</h5>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Services View: </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="ServicesView"
                            defaultChecked={ras.ServicesView}
                            value={ras.ServicesView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Services Category List : </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="ServicesCategoryView"
                            defaultChecked={ras.ServicesCategoryView}
                            value={ras.ServicesCategoryView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="ServicesCategoryAdd"
                            defaultChecked={ras.ServicesCategoryAdd}
                            value={ras.ServicesCategoryAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empAdd">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="ServicesCategoryEdit"
                            defaultChecked={ras.ServicesCategoryEdit}
                            value={ras.ServicesCategoryEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="ServicesCategoryDelete"
                            defaultChecked={ras.ServicesCategoryDelete}
                            value={ras.ServicesCategoryDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empl3">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Services Sub Category List : </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="ServicesSubCategoryView"
                            defaultChecked={ras.ServicesSubCategoryView}
                            value={ras.ServicesSubCategoryView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="ServicesSubCategoryAdd"
                            defaultChecked={ras.ServicesSubCategoryAdd}
                            value={ras.ServicesSubCategoryAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empAdd">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="ServicesSubCategoryEdit"
                            defaultChecked={ras.ServicesSubCategoryEdit}
                            value={ras.ServicesSubCategoryEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="ServicesSubCategoryDelete"
                            defaultChecked={ras.ServicesSubCategoryDelete}
                            value={ras.ServicesSubCategoryDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empl3">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Services List : </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="ServicesView"
                            defaultChecked={ras.ServicesView}
                            value={ras.ServicesView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="ServicesAdd"
                            defaultChecked={ras.ServicesAdd}
                            value={ras.ServicesAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empAdd">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="ServicesEdit"
                            defaultChecked={ras.ServicesEdit}
                            value={ras.ServicesEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="ServicesDelete"
                            defaultChecked={ras.ServicesDelete}
                            value={ras.ServicesDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empl3">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Category List : </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="CategoryView"
                            defaultChecked={ras.CategoryView}
                            value={ras.CategoryView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="CategoryAdd"
                            defaultChecked={ras.CategoryAdd}
                            value={ras.CategoryAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empAdd">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="CategoryEdit"
                            defaultChecked={ras.CategoryEdit}
                            value={ras.CategoryEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="CategoryDelete"
                            defaultChecked={ras.CategoryDelete}
                            value={ras.CategoryDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empl3">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <h5 className="mt-3 mb-3">User List :</h5>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">User List View: </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="UserListView"
                            defaultChecked={ras.UserListView}
                            value={ras.UserListView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">User List : </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="userListView"
                            defaultChecked={ras.userListView}
                            value={ras.userListView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="userListBlock"
                            defaultChecked={ras.userListBlock}
                            value={ras.userListBlock}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empl3">
                            Block
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <h5 className="mt-3 mb-3"> Booking Managment :</h5>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Booking Managment View : </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="BookingManagmentView"
                            defaultChecked={ras.BookingManagmentView}
                            value={ras.BookingManagmentView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <h5 className="mt-3 mb-3">Payments :</h5>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Payments : </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="paymentsView"
                            defaultChecked={ras.paymentsView}
                            value={ras.paymentsView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="paymentsEdit"
                            defaultChecked={ras.paymentsEdit}
                            value={ras.paymentsEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <h5 className="mt-3 mb-3">Banners :</h5>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Banners View: </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="BannerView"
                            defaultChecked={ras.BannerView}
                            value={ras.BannerView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Banners: </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="bannersView"
                            defaultChecked={ras.bannersView}
                            value={ras.bannersView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="bannersAdd"
                            defaultChecked={ras.bannersAdd}
                            value={ras.bannersAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empAdd">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="bannersEdit"
                            defaultChecked={ras.bannersEdit}
                            value={ras.bannersEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="bannersDelete"
                            defaultChecked={ras.bannersDelete}
                            value={ras.bannersDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empl3">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Sub Banners: </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="suBannersView"
                            defaultChecked={ras.suBannersView}
                            value={ras.suBannersView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="suBannersAdd"
                            defaultChecked={ras.suBannersAdd}
                            value={ras.suBannersAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empAdd">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="suBannersEdit"
                            defaultChecked={ras.suBannersEdit}
                            value={ras.suBannersEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="suBannersDelete"
                            defaultChecked={ras.suBannersDelete}
                            value={ras.suBannersDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empl3">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <h5 className="mt-3 mb-3">Coupons :</h5>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Coupons : </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="couponsView"
                            defaultChecked={ras.couponsView}
                            value={ras.couponsView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="couponsAdd"
                            defaultChecked={ras.couponsAdd}
                            value={ras.couponsAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empAdd">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="couponsEdit"
                            defaultChecked={ras.couponsEdit}
                            value={ras.couponsEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <h5 className="mt-3 mb-3">Notifications:</h5>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Notifications : </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="notificationsView"
                            defaultChecked={ras.notificationsView}
                            value={ras.notificationsView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="bookinglist">
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="notificationsAdd"
                            defaultChecked={ras.notificationsAdd}
                            value={ras.notificationsAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="bookinglist">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="notificationsDelete"
                            defaultChecked={ras.notificationsDelete}
                            value={ras.notificationsDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="bookinglist">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <h5 className="mt-3 mb-3">Settings :</h5>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Settings Section: </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="SettingsView"
                            defaultChecked={ras.SettingsView}
                            value={ras.SettingsView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Settings: </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="settingsAdd"
                            defaultChecked={ras.settingsAdd}
                            value={ras.settingsAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="laneview">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="settingsEdit"
                            defaultChecked={ras.settingsEdit}
                            value={ras.settingsEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="laneview">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="settingsView"
                            defaultChecked={ras.settingsView}
                            value={ras.settingsView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="laneview">
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="settingsDelete"
                            defaultChecked={ras.settingsDelete}
                            value={ras.settingsDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="laneview">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <div className="mt-3" style={{ float: "right" }}>
                      <button
                        type="submit"
                        style={{ width: "120px" }}
                        className="btn btn-primary m-1"
                      >
                        Submit
                        <i
                          className="fa fa-check-circle-o"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default Roles
