import React, { useState, useEffect } from "react"
import {
  CardBody,
  Container,
  Row,
  Col,
  Card,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer } from "react-toastify"
import classnames from "classnames"
import { useHistory } from "react-router-dom"
import axios from "axios"
import { URLS } from "../../Url"
import gig from "../../assets/images/logos.png"
import ReactPaginate from "react-paginate"
import StarRatings from "react-star-ratings"

function State() {
  const [forms, setforms] = useState([])

  const [Documents, setDocuments] = useState([])

  const [formsmap, setformsmap] = useState([])

  const [Vendor, setVendor] = useState([])

  const [bank, setbank] = useState([])

  const [review, setreview] = useState([])

  const [staff, setstaff] = useState([])

  const [Gallery, setGallery] = useState([])

  const [isLoading, setIsLoading] = useState(false)

  const [activeTab1, setactiveTab1] = useState("5")

  const [PendingBookings, setPendingBookings] = useState([])

  const [CompletedBookings, setCompletedBookings] = useState([])

  const [CancelledBooking, setCancelledBooking] = useState([])

  const [Subscription, setSubscription] = useState([])

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }

  const history = useHistory()

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  useEffect(() => {
    GetOneOrder()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const OrderId = sessionStorage.getItem("UserId")

  const GetOneOrder = () => {
    const data = {
      vendorId: OrderId,
    }

    var token = datas
    axios
      .post(URLS.GetVendorIdList, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setforms(res.data.salon[0])
        setVendor(res.data.vendor[0])
        setbank(res.data.bank[0])
        setreview(res.data.review)
        setGallery(res.data.gallery)
        setstaff(res.data.staff)
        setPendingBookings(res.data.pendingBookings)
        setCompletedBookings(res.data.completedBookings)
        setCancelledBooking(res.data.cancelledBookings)
        setSubscription(res.data.subscribe[0])
        setDocuments(res.data.salon[0].documents)
        setformsmap(res.data.salon[0].maplocation.coordinates)
        setIsLoading(false)
      })
  }

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = staff.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(staff.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const [listPerPage1] = useState(5)
  const [pageNumber1, setPageNumber1] = useState(0)

  const pagesVisited1 = pageNumber1 * listPerPage1
  const lists1 = PendingBookings.slice(
    pagesVisited1,
    pagesVisited1 + listPerPage1
  )
  const pageCount1 = Math.ceil(PendingBookings.length / listPerPage1)
  const changePage1 = ({ selected }) => {
    setPageNumber1(selected)
  }

  const [listPerPage2] = useState(5)
  const [pageNumber2, setPageNumber2] = useState(0)

  const pagesVisited2 = pageNumber2 * listPerPage2
  const lists2 = CompletedBookings.slice(
    pagesVisited2,
    pagesVisited2 + listPerPage2
  )
  const pageCount2 = Math.ceil(CompletedBookings.length / listPerPage2)
  const changePage2 = ({ selected }) => {
    setPageNumber2(selected)
  }

  const [listPerPage3] = useState(20)
  const [pageNumber3, setPageNumber3] = useState(0)

  const pagesVisited3 = pageNumber3 * listPerPage3
  const lists3 = CancelledBooking.slice(
    pagesVisited3,
    pagesVisited3 + listPerPage3
  )
  const pageCount3 = Math.ceil(CancelledBooking.length / listPerPage3)
  const changePage3 = ({ selected }) => {
    setPageNumber3(selected)
  }

  const Bookingsid = data => {
    sessionStorage.setItem("BookingId", data.orderId)
    history.push("/BookingView")
  }

  const [listPerPage4] = useState(5)
  const [pageNumber4, setPageNumber4] = useState(0)

  const pagesVisited4 = pageNumber4 * listPerPage4
  const lists4 = review.slice(pagesVisited4, pagesVisited4 + listPerPage4)
  const pageCount4 = Math.ceil(review.length / listPerPage4)
  const changePage4 = ({ selected }) => {
    setPageNumber4(selected)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Go Cut" breadcrumbItem="View Vendor" />
          <Row>
            <Col>
              <Button
                onClick={() => history.goBack()}
                className="mb-3  m-1 "
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i> Back
              </Button>
            </Col>
          </Row>
          {isLoading == true ? (
            <>
              <div
                style={{ zIndex: "9999999999999", height: "420px" }}
                className="text-center mt-5 pt-5"
              >
                <img src={gig} height="140px"></img>
                <div>Loading......</div>
              </div>
            </>
          ) : (
            <>
              <Row className="mb-5">
                <Col md={12}>
                  <Row>
                    <Col md={3}>
                      <Card>
                        <h5
                          className="text-primary pb-1 pt-1"
                          style={{ borderBottom: "1px solid grey" }}
                        >
                          View Vendor Details :-
                        </h5>

                        <Nav pills className="flex-column">
                          <NavItem className="mt-2">
                            <NavLink
                              style={{
                                border: "1px #63183F solid",
                                borderRadius: "5px",
                                padding: "5px",
                                margin: "4px",
                                cursor: "pointer",
                              }}
                              className={classnames({
                                active: activeTab1 === "5",
                              })}
                              onClick={() => {
                                toggle1("5")
                              }}
                            >
                              {" "}
                              <span className="bx bx-chevrons-right"></span>
                              Salon Information
                            </NavLink>
                          </NavItem>
                          <NavItem className="mt-2">
                            <NavLink
                              style={{
                                border: "1px #63183F solid",
                                borderRadius: "5px",
                                padding: "5px",
                                margin: "4px",
                                cursor: "pointer",
                              }}
                              className={classnames({
                                active: activeTab1 === "6",
                              })}
                              onClick={() => {
                                toggle1("6")
                              }}
                            >
                              {" "}
                              <span className="bx bx-chevrons-right"></span>
                              Vendor Information
                            </NavLink>
                          </NavItem>
                          <NavItem className="mt-2">
                            <NavLink
                              style={{
                                border: "1px #63183F solid",
                                borderRadius: "5px",
                                padding: "5px",
                                margin: "4px",
                                cursor: "pointer",
                              }}
                              className={classnames({
                                active: activeTab1 === "7",
                              })}
                              onClick={() => {
                                toggle1("7")
                              }}
                            >
                              {" "}
                              <span className="bx bx-chevrons-right"></span>
                              Documents
                            </NavLink>
                          </NavItem>
                          <NavItem className="mt-2">
                            <NavLink
                              style={{
                                border: "1px #63183F solid",
                                borderRadius: "5px",
                                padding: "5px",
                                margin: "4px",
                                cursor: "pointer",
                              }}
                              className={classnames({
                                active: activeTab1 === "8",
                              })}
                              onClick={() => {
                                toggle1("8")
                              }}
                            >
                              {" "}
                              <span className="bx bx-chevrons-right"></span>
                              Pending Bookings ({PendingBookings.length})
                            </NavLink>
                          </NavItem>
                          <NavItem className="mt-2">
                            <NavLink
                              style={{
                                border: "1px #63183F solid",
                                borderRadius: "5px",
                                padding: "5px",
                                margin: "4px",
                                cursor: "pointer",
                              }}
                              className={classnames({
                                active: activeTab1 === "9",
                              })}
                              onClick={() => {
                                toggle1("9")
                              }}
                            >
                              {" "}
                              <span className="bx bx-chevrons-right"></span>
                              Completed Bookings ({CompletedBookings.length})
                            </NavLink>
                          </NavItem>
                          <NavItem className="mt-2">
                            <NavLink
                              style={{
                                border: "1px #63183F solid",
                                borderRadius: "5px",
                                padding: "5px",
                                margin: "4px",
                                cursor: "pointer",
                              }}
                              className={classnames({
                                active: activeTab1 === "10",
                              })}
                              onClick={() => {
                                toggle1("10")
                              }}
                            >
                              Cancelled Bookings ({CancelledBooking.length})
                            </NavLink>
                          </NavItem>
                          <NavItem className="mt-2">
                            <NavLink
                              style={{
                                border: "1px #63183F solid",
                                borderRadius: "5px",
                                padding: "5px",
                                margin: "4px",
                                cursor: "pointer",
                              }}
                              className={classnames({
                                active: activeTab1 === "12",
                              })}
                              onClick={() => {
                                toggle1("12")
                              }}
                            >
                              {" "}
                              <span className="bx bx-chevrons-right"></span>
                              Subscription Details
                            </NavLink>
                          </NavItem>
                          <NavItem className="mt-2">
                            <NavLink
                              style={{
                                border: "1px #63183F solid",
                                borderRadius: "5px",
                                padding: "5px",
                                margin: "4px",
                                cursor: "pointer",
                              }}
                              className={classnames({
                                active: activeTab1 === "13",
                              })}
                              onClick={() => {
                                toggle1("13")
                              }}
                            >
                              {" "}
                              <span className="bx bx-chevrons-right"></span>
                              Staff Details
                            </NavLink>
                          </NavItem>
                          <NavItem className="mt-2">
                            <NavLink
                              style={{
                                border: "1px #63183F solid",
                                borderRadius: "5px",
                                padding: "5px",
                                margin: "4px",
                                cursor: "pointer",
                              }}
                              className={classnames({
                                active: activeTab1 === "14",
                              })}
                              onClick={() => {
                                toggle1("14")
                              }}
                            >
                              {" "}
                              <span className="bx bx-chevrons-right"></span>
                              Gallery
                            </NavLink>
                          </NavItem>
                          <NavItem className="mt-2">
                            <NavLink
                              style={{
                                border: "1px #63183F solid",
                                borderRadius: "5px",
                                padding: "5px",
                                margin: "4px",
                                cursor: "pointer",
                              }}
                              className={classnames({
                                active: activeTab1 === "15",
                              })}
                              onClick={() => {
                                toggle1("15")
                              }}
                            >
                              {" "}
                              <span className="bx bx-chevrons-right"></span>
                              Reviews & Ratings
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </Card>
                    </Col>
                    <Col md={9}>
                      <Card>
                        <CardBody>
                          <TabContent
                            activeTab={activeTab1}
                            className="text-muted"
                          >
                            <TabPane tabId="5">
                              <Row>
                                <Col lg={6}>
                                  <h5 className="mb-4 text-primary">
                                    Salon Information :-
                                  </h5>
                                  <ul className="list-unstyled vstack gap-3 mb-0">
                                    <img
                                      src={URLS.Base + forms?.logo}
                                      height="150px"
                                      width="50%"
                                    ></img>
                                    <span
                                      style={{ fontWeight: "bold" }}
                                      className="text-primary"
                                    >
                                      (Rating :
                                      <StarRatings
                                        rating={4}
                                        starRatedColor="#F1B44C"
                                        starEmptyColor="#2D363F"
                                        numberOfStars={5}
                                        name="rating"
                                        starDimension="14px"
                                        starSpacing="3px"
                                      />
                                      )
                                    </span>
                                    <li>
                                      <div className="d-flex mt-3">
                                        <i className="bx bx-buildings font-size-18 text-primary"></i>
                                        <div className="ms-3">
                                          <h6 className="mb-1 fw-semibold">
                                            Salon Name:
                                          </h6>
                                          <span className="text-muted">
                                            {forms?.name}
                                          </span>
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="d-flex  mt-3">
                                        <i className="bx bx-phone font-size-18 text-primary"></i>
                                        <div className="ms-3">
                                          <h6 className="mb-1 fw-semibold">
                                            Salon Contact Number:
                                          </h6>
                                          {forms?.mobileNumber}
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="d-flex  mt-3">
                                        <i className="bx bx-user font-size-18 text-primary"></i>
                                        <div className="ms-3">
                                          <h6 className="mb-1 fw-semibold">
                                            Category :
                                          </h6>
                                          {forms?.categoryName}
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="d-flex mt-3">
                                        <i className="bx bx-cut font-size-18 text-primary"></i>
                                        <div className="ms-3">
                                          <h6 className="mb-1 fw-semibold">
                                            Seating:
                                          </h6>
                                          <span className="text-muted">
                                            {forms?.seats}
                                          </span>
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="d-flex mt-3">
                                        <i className="bx bx-wallet-alt font-size-18 text-primary"></i>
                                        <div className="ms-3">
                                          <h6 className="mb-1 fw-semibold">
                                            Recharge Amount / Wallet :
                                          </h6>
                                          <span className="text-muted">
                                            {Vendor?.wallet}
                                          </span>
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="d-flex  mt-3">
                                        <i className="bx bx-broadcast font-size-18 text-primary"></i>
                                        <div className="ms-3">
                                          <h6 className="mb-1 fw-semibold">
                                            Online Status :
                                          </h6>
                                          {forms?.store_status == true ? (
                                            <>
                                              {" "}
                                              <span className="badge bg-success">
                                                Online
                                              </span>
                                            </>
                                          ) : (
                                            <>
                                              <span className="badge bg-danger">
                                                Offline
                                              </span>{" "}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </Col>
                                <Col lg={6}>
                                  <ul className="verti-timeline list-unstyled ">
                                    <h5 className="mb-4 text-primary">
                                      Address Details :-
                                    </h5>
                                    <li className="event-list mt-5">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 mb-1">
                                              Address
                                            </h6>
                                            <p className="text-muted">
                                              {forms?.address}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 mb-1">
                                              City
                                            </h6>
                                            <p className="text-muted">
                                              {forms?.city}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 mb-1">
                                              Lattitude / Longitude
                                            </h6>
                                            <p className="text-muted">
                                              {formsmap.map((data, key) => (
                                                <span key={key}>
                                                  {" "}
                                                  {key + 1}) {data}
                                                  <br></br>
                                                </span>
                                              ))}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Status
                                            </h6>
                                            <p className="text-muted">
                                              {forms?.store_status == true ? (
                                                <>Active</>
                                              ) : (
                                                <>Inactive</>
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </Col>
                              </Row>
                            </TabPane>
                            <TabPane tabId="6">
                              <Row>
                                <Col md="6">
                                  <h5 className=" text-primary mb-5">
                                    Vendor Information :
                                  </h5>
                                  <ul className="verti-timeline list-unstyled">
                                    <li className="event-list  mt-2">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Name
                                            </h6>
                                            <p className="text-muted">
                                              {" "}
                                              {Vendor?.name}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Mobile
                                            </h6>
                                            <p className="text-muted">
                                              {Vendor?.mobileNumber}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Email
                                            </h6>
                                            <p className="text-muted">
                                              {Vendor?.email}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Designation
                                            </h6>
                                            <p className="text-muted">
                                              {" "}
                                              {Vendor?.designation}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Status
                                            </h6>
                                            <p className="text-muted">
                                              {Vendor?.status}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </Col>
                                <Col md="6">
                                  <h5 className=" text-primary mb-5">
                                    Bank Information :
                                  </h5>
                                  <ul className="verti-timeline list-unstyled">
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Bank User Name
                                            </h6>
                                            <p className="text-muted">
                                              {" "}
                                              {bank?.vendorName}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Bank Name
                                            </h6>
                                            <p className="text-muted">
                                              {bank?.bankName}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Bank Account Number
                                            </h6>
                                            <p className="text-muted">
                                              {" "}
                                              {bank?.accountNumber}{" "}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Branch Name
                                            </h6>
                                            <p className="text-muted">
                                              {bank?.branch}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              IFSC CODE
                                            </h6>
                                            <p className="text-muted">
                                              {bank?.IfscCode}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </Col>
                              </Row>
                            </TabPane>
                            <TabPane tabId="7">
                              <Row className="mt-4">
                                <Col md={6}>
                                  <h5 className="text-primary">Documents :-</h5>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6}>
                                  <ul className="verti-timeline list-unstyled">
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 mb-3 "></h6>
                                            <p className="text-muted">
                                              {Documents.map((data, key) => (
                                                <a
                                                  href={URLS.Base + data}
                                                  key={key}
                                                  target="_blank"
                                                  rel="noreferrer"
                                                >
                                                  <iframe
                                                    src={URLS.Base + data}
                                                    height="300px"
                                                    width="100%"
                                                  ></iframe>
                                                </a>
                                              ))}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </Col>
                              </Row>
                            </TabPane>
                            <TabPane tabId="8">
                              <h5
                                className="mb-3 text-primary"
                                style={{ textDecoration: "underline" }}
                              >
                                Pending Bookings
                              </h5>
                              <div className="table-responsive">
                                <Table className="table table-bordered mb-2 mt-3">
                                  <thead>
                                    <tr className="text-center">
                                      <th>S.No</th>
                                      <th>Booking Id</th>
                                      <th>User Name</th>
                                      <th>Contact No</th>
                                      <th>Services Details</th>
                                      <th>Date/Time</th>
                                      <th>Status</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {lists1.map((data, key) => (
                                      <tr key={key} className="text-center">
                                        <td>{pagesVisited1 + key + 1}</td>{" "}
                                        {/* Corrected serial number calculation */}
                                        <td>{data.bookingId}</td>
                                        <td>{data.userName}</td>
                                        <td>{data.userContact}</td>
                                        <td>
                                          {data?.type === "Combo" ? (
                                            <> {data?.type} </>
                                          ) : (
                                            <> {data?.serviceName} </>
                                          )}
                                        </td>
                                        <td>
                                          {data.date}/{data.time}
                                        </td>
                                        <td>
                                          <span className="badge bg-warning">
                                            {data.status}
                                          </span>
                                        </td>
                                        <td>
                                          <Button
                                            className="mr-2"
                                            style={{
                                              padding: "6px",
                                              margin: "3px",
                                            }}
                                            onClick={() => {
                                              Bookingsid(data)
                                            }}
                                            color="info"
                                            outline
                                          >
                                            <i className="fas fa-eye"></i>
                                          </Button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                                <div
                                  className="d-flex mt-3 mb-1"
                                  style={{ float: "right" }}
                                >
                                  <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    pageCount={pageCount1}
                                    onPageChange={changePage1}
                                    containerClassName={"pagination"}
                                    previousLinkClassName={"previousBttn"}
                                    nextLinkClassName={"nextBttn"}
                                    disabledClassName={"disabled"}
                                    activeClassName={"active"}
                                  />
                                </div>
                              </div>
                            </TabPane>
                            <TabPane tabId="9">
                              <h5
                                className="mb-3 text-primary"
                                style={{ textDecoration: "underline" }}
                              >
                                Completed Bookings
                              </h5>
                              <div className="table-responsive">
                                <Table className="table table-bordered mb-2 mt-3">
                                  <thead>
                                    <tr className="text-center">
                                      <th>S.No</th>
                                      <th>Booking Id</th>
                                      <th>User Name</th>
                                      <th>Contact No</th>
                                      <th>Services Details</th>
                                      <th>Date/Time</th>
                                      <th>Status</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {lists2.map((data, key) => (
                                      <tr key={key} className="text-center">
                                        <td>{pagesVisited2 + key + 1}</td>{" "}
                                        {/* Corrected serial number calculation */}
                                        <td>{data.bookingId}</td>
                                        <td>{data.userName}</td>
                                        <td>{data.userContact}</td>
                                        <td>
                                          {data?.type === "Combo" ? (
                                            <> {data?.type} </>
                                          ) : (
                                            <> {data?.serviceName} </>
                                          )}
                                        </td>
                                        <td>
                                          {data.date}/{data.time}
                                        </td>
                                        <td>
                                          <span className="badge bg-success">
                                            {data.status}
                                          </span>
                                        </td>
                                        <td>
                                          <Button
                                            className="mr-2"
                                            style={{
                                              padding: "6px",
                                              margin: "3px",
                                            }}
                                            onClick={() => {
                                              Bookingsid(data)
                                            }}
                                            color="info"
                                            outline
                                          >
                                            <i className="fas fa-eye"></i>
                                          </Button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                                <div
                                  className="d-flex mt-3 mb-1"
                                  style={{ float: "right" }}
                                >
                                  <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    pageCount={pageCount2}
                                    onPageChange={changePage2}
                                    containerClassName={"pagination"}
                                    previousLinkClassName={"previousBttn"}
                                    nextLinkClassName={"nextBttn"}
                                    disabledClassName={"disabled"}
                                    activeClassName={"active"}
                                  />
                                </div>
                              </div>
                            </TabPane>
                            <TabPane tabId="10">
                              <h5
                                className="mb-3 text-primary"
                                style={{ textDecoration: "underline" }}
                              >
                                Cancelled Bookings
                              </h5>
                              <div className="table-responsive">
                                <Table className="table table-bordered mb-2 mt-3">
                                  <thead>
                                    <tr className="text-center">
                                      <th>S.No</th>
                                      <th>Booking Id</th>
                                      <th>User Name</th>
                                      <th>Contact No</th>
                                      <th>Services Details</th>
                                      <th>Date/Time</th>
                                      <th>Status</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {lists3.map((data, key) => (
                                      <tr key={key} className="text-center">
                                        <td>{pagesVisited3 + key + 1}</td>{" "}
                                        {/* Corrected serial number calculation */}
                                        <td>{data.bookingId}</td>
                                        <td>{data.userName}</td>
                                        <td>{data.userContact}</td>
                                        <td>
                                          {data?.type === "Combo" ? (
                                            <> {data?.type} </>
                                          ) : (
                                            <> {data?.serviceName} </>
                                          )}
                                        </td>
                                        <td>
                                          {data.date}/{data.time}
                                        </td>
                                        <td>
                                          <span className="badge bg-danger">
                                            {data.status}
                                          </span>
                                        </td>
                                        <td>
                                          <Button
                                            className="mr-2"
                                            style={{
                                              padding: "6px",
                                              margin: "3px",
                                            }}
                                            onClick={() => {
                                              Bookingsid(data)
                                            }}
                                            color="info"
                                            outline
                                          >
                                            <i className="fas fa-eye"></i>
                                          </Button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                                <div
                                  className="d-flex mt-3 mb-1"
                                  style={{ float: "right" }}
                                >
                                  <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    pageCount={pageCount3}
                                    onPageChange={changePage3}
                                    containerClassName={"pagination"}
                                    previousLinkClassName={"previousBttn"}
                                    nextLinkClassName={"nextBttn"}
                                    disabledClassName={"disabled"}
                                    activeClassName={"active"}
                                  />
                                </div>
                              </div>
                            </TabPane>
                            <TabPane tabId="12">
                              <h5 className="text-primary">
                                Subscription Details :-
                              </h5>
                              <Row className="mt-4">
                                <Col md={2}> </Col>
                                <Col md={8}>
                                  <div className="KKL">
                                    <h4
                                      style={{
                                        color: "white",
                                        fontSize: "17px",
                                        textDecoration: "underline",
                                      }}
                                    >
                                      Currect Plan
                                    </h4>
                                    <Row className="mt-3">
                                      <Col md={6}>
                                        <i
                                          className="fas fa-crown"
                                          style={{
                                            color: "#C4C2C4",
                                            fontSize: "30px",
                                          }}
                                        ></i>
                                        <span
                                          style={{
                                            color: "white",
                                            fontSize: "17px",
                                          }}
                                        >
                                          {Subscription?.planName}
                                        </span>
                                      </Col>
                                      <Col md={4}>
                                        <del
                                          style={{
                                            color: "#C4C2C4",
                                          }}
                                        >
                                          ₹ {Subscription?.finalPrice}
                                        </del>
                                        <h4 style={{ color: "white" }}>
                                          ₹ {Subscription?.planOfferPrice}
                                        </h4>
                                      </Col>
                                    </Row>
                                    <Row className="mb-2 mt-3">
                                      <Col md={4}>
                                        <p
                                          style={{
                                            color: "#C4C2C4",
                                            fontSize: "10px",
                                          }}
                                        >
                                          Start date
                                        </p>
                                        <h6 style={{ color: "white" }}>
                                          {Subscription?.startDate}
                                        </h6>
                                      </Col>
                                      <Col md={4}>
                                        <p
                                          style={{
                                            color: "#C4C2C4",
                                            fontSize: "10px",
                                          }}
                                        >
                                          End date
                                        </p>
                                        <h6 style={{ color: "white" }}>
                                          {Subscription?.endDate}
                                        </h6>
                                      </Col>
                                      <Col md={4}>
                                        <p
                                          style={{
                                            color: "#C4C2C4",
                                            fontSize: "10px",
                                          }}
                                        >
                                          SubscriberType
                                        </p>
                                        <h6 style={{ color: "white" }}>
                                          {Subscription?.subscriberType}
                                        </h6>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                                <Col md={2}> </Col>
                              </Row>{" "}
                              <hr></hr>
                            </TabPane>
                            <TabPane tabId="13">
                              <h5 className="text-primary mb-3">
                                Staff Details :-
                              </h5>
                              <Row className="mt-4">
                                <Col md={12}>
                                  <div className="table-rep-plugin table-responsive">
                                    <Table hover bordered responsive>
                                      <thead>
                                        <tr className="text-center">
                                          <th>Sl No</th>
                                          <th>Name</th>
                                          <th>Email</th>
                                          <th>Phone No</th>
                                          <th>Alt Phone No</th>
                                          <th>Age</th>
                                          <th>Gender</th>
                                          <th>Profession</th>
                                          <th>Documents / Image </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {lists.map((data, key) => (
                                          <tr key={key} className="text-center">
                                            <th scope="row">
                                              {(pageNumber - 1) * 5 + key + 6}
                                            </th>
                                            <td>{data.name}</td>
                                            <td>{data.email}</td>
                                            <td>{data.mobileNumber}</td>
                                            <td>{data.altMobileNumber}</td>
                                            <td>{data.age}</td>
                                            <td>{data.gender}</td>
                                            <td>{data.expertIn}</td>
                                            <td>
                                              <a
                                                href={
                                                  URLS.Base + data.documents
                                                }
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                <img
                                                  src={
                                                    URLS.Base + data.documents
                                                  }
                                                  height="50px"
                                                ></img>
                                              </a>
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </Table>
                                    <Col sm="12">
                                      <div
                                        className="d-flex mt-3 mb-1"
                                        style={{ float: "right" }}
                                      >
                                        <ReactPaginate
                                          previousLabel={"Previous"}
                                          nextLabel={"Next"}
                                          pageCount={pageCount}
                                          onPageChange={changePage}
                                          containerClassName={"pagination"}
                                          previousLinkClassName={"previousBttn"}
                                          nextLinkClassName={"nextBttn"}
                                          disabledClassName={"disabled"}
                                          activeClassName={"active"}
                                          total={lists.length}
                                        />
                                      </div>
                                    </Col>
                                  </div>
                                </Col>
                              </Row>
                            </TabPane>
                            <TabPane tabId="14">
                              <h5 className="text-primary mb-3">Gallery :- </h5>
                              <Row>
                                {Gallery.map((data, key) => (
                                  <Col
                                    md={3}
                                    className="text-center mt-3"
                                    key={key}
                                  >
                                    <img
                                      src={URLS.Base + data.image}
                                      height="200px"
                                      width="90%"
                                    ></img>
                                  </Col>
                                ))}
                              </Row>
                            </TabPane>
                            <TabPane tabId="15">
                              <h5 className="text-primary mb-3">Reviews :-</h5>
                              <Row className="mt-4">
                                {lists4.map((data, key) => (
                                  <>
                                    {data.rating == 0 ? (
                                      <></>
                                    ) : (
                                      <>
                                        <Col md={12} key={key}>
                                          <hr></hr>
                                          <div className="d-flex mt-4">
                                            <img
                                              src={URLS.Base + forms?.logo}
                                              className="avatar-md me-3 rounded-circle"
                                              alt="img"
                                            />
                                            <div className="flex-grow-1">
                                              <h5 className="mt-0 mb-1 font-size-15">
                                                {data.userName} :{" "}
                                                <span
                                                  style={{ fontWeight: "bold" }}
                                                  className="text-primary"
                                                >
                                                  (Rating :
                                                  <StarRatings
                                                    rating={Number(data.rating)}
                                                    starRatedColor="#F1B44C"
                                                    starEmptyColor="#2D363F"
                                                    numberOfStars={5}
                                                    name="rating"
                                                    starDimension="14px"
                                                    starSpacing="3px"
                                                  />
                                                  )
                                                </span>
                                              </h5>
                                              <div className="text-muted font-size-12">
                                                <i className="far fa-calendar-alt text-primary me-1" />
                                                {data?.logCreatedDate.slice(
                                                  0,
                                                  10
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </Col>
                                      </>
                                    )}
                                  </>
                                ))}
                                <Col sm="12">
                                  <div
                                    className="d-flex mt-3 mb-1"
                                    style={{ float: "right" }}
                                  >
                                    <ReactPaginate
                                      previousLabel={"Previous"}
                                      nextLabel={"Next"}
                                      pageCount={pageCount4}
                                      onPageChange={changePage4}
                                      containerClassName={"pagination"}
                                      previousLinkClassName={"previousBttn"}
                                      nextLinkClassName={"nextBttn"}
                                      disabledClassName={"disabled"}
                                      activeClassName={"active"}
                                      total={lists4.length}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </TabPane>
                          </TabContent>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          )}
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default State
