import React, { useState, useEffect } from "react"
import {
  CardBody,
  Container,
  Row,
  Col,
  Card,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer } from "react-toastify"
import classnames from "classnames"
import { useHistory } from "react-router-dom"
import axios from "axios"
import { URLS } from "../../Url"

function State() {
  const [forms, setforms] = useState([])

  const [Doc, setDoc] = useState([])

  const [activeTab1, setactiveTab1] = useState("5")

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }

  const history = useHistory()

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  useEffect(() => {
    GetOneOrder()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const OrderId = sessionStorage.getItem("PendindBookId")

  const GetOneOrder = () => {
    const data = {
      id: OrderId,
    }

    var token = datas
    axios
      .post(URLS.PendingGetOneVendor, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setforms(res?.data?.data[0])
        setDoc(res?.data?.data[0]?.salonDocuments)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Go Cut" breadcrumbItem="View Vendor" />

          <Row>
            <Col>
              <Button
                onClick={() => history.goBack()}
                className="mb-3  m-1 "
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i> Back
              </Button>
            </Col>
          </Row>

          <Row className="mb-5">
            <Col md={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={8}>
                      <Nav pills className="navtab-bg nav-justified">
                        <NavItem>
                          <NavLink
                            style={{
                              border: "1px #63183F solid",
                              borderRadius: "5px",
                              padding: "5px",
                              margin: "4px",
                            }}
                            className={classnames({
                              active: activeTab1 === "5",
                            })}
                            onClick={() => {
                              toggle1("5")
                            }}
                          >
                            Profile
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{
                              border: "1px #63183F solid",
                              borderRadius: "5px",
                              padding: "5px",
                              margin: "4px",
                              marginRight: "2px",
                            }}
                            className={classnames({
                              active: activeTab1 === "8",
                            })}
                            onClick={() => {
                              toggle1("8")
                            }}
                          >
                            Salon Document
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Col>
                  </Row>
                  <TabContent
                    activeTab={activeTab1}
                    className="p-4  text-muted"
                  >
                    <TabPane tabId="5">
                      <Row className="mt-3">
                        <Col lg={4}>
                          <h5 className="mb-4 text-primary">
                            Salon Information :-
                          </h5>
                          <ul className="list-unstyled vstack gap-3 mb-0">
                            <img
                              src={URLS.Base + forms.salonLogo}
                              height="100px"
                              width="120px"
                            ></img>
                            <li>
                              <div className="d-flex mt-3">
                                <i className="bx bx-buildings font-size-18 text-primary"></i>
                                <div className="ms-3">
                                  <h6 className="mb-1 fw-semibold">
                                    Salon Name:
                                  </h6>
                                  <span className="text-muted">
                                    {forms.salonName}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex  mt-3">
                                <i className="bx bx-phone font-size-18 text-primary"></i>
                                <div className="ms-3">
                                  <h6 className="mb-1 fw-semibold">
                                    Salon Contact Number:
                                  </h6>
                                  {forms.salonMobileNumber}
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex  mt-3">
                                <i className="bx bx-user font-size-18 text-primary"></i>
                                <div className="ms-3">
                                  <h6 className="mb-1 fw-semibold">
                                    Category:
                                  </h6>
                                  {forms.categoryName}
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex mt-3">
                                <i className="bx bx-cut font-size-18 text-primary"></i>
                                <div className="ms-3">
                                  <h6 className="mb-1 fw-semibold">Seating:</h6>
                                  <span className="text-muted">
                                    {" "}
                                    {forms.salonSeating}
                                  </span>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </Col>
                        <Col lg={4}>
                          <ul className="verti-timeline list-unstyled ">
                            <h5 className="mb-4 text-primary">
                              Address Details :-
                            </h5>
                            <li className="event-list mt-5">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Address
                                    </h6>
                                    <p className="text-muted">
                                      {forms.salonAddress}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">City</h6>
                                    <p className="text-muted">
                                      {" "}
                                      {forms.salonCity}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Lattitude - Longitude
                                    </h6>
                                    <p className="text-muted">
                                      {forms.salonLatitude} -
                                      {forms.salonLongitude}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 ">
                                      Designation
                                    </h6>
                                    <p className="text-muted">
                                      {" "}
                                      {forms.designation}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </Col>
                        <Col md="4">
                          <h5 className=" text-primary mb-5">
                            Vendor Information :-
                          </h5>
                          <ul className="verti-timeline list-unstyled">
                            <li className="event-list  mt-2">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 ">Name</h6>
                                    <p className="text-muted">{forms.name}</p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 ">Mobile</h6>
                                    <p className="text-muted">
                                      {forms.mobileNumber}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 ">Email</h6>
                                    <p className="text-muted">{forms.email}</p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="8">
                      <Row className="mt-4">
                        <Col md={6}>
                          <h5 className="text-primary">Salon Document :-</h5>
                        </Col>
                      </Row>
                      <Row>
                        {Doc?.map((data, i) => (
                          <Col md={6} key={i}>
                            <ul className="verti-timeline list-unstyled">
                              <li className="event-list">
                                <div className="event-timeline-dot">
                                  <i className="bx bx-right-arrow-circle"></i>
                                </div>
                                <div className="d-flex">
                                  <div className="flex-grow-1">
                                    <div>
                                      <h6 className="font-size-14 mb-3 "></h6>
                                      <p className="text-muted">
                                        <a
                                          href={URLS.Base + data}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <iframe
                                            src={URLS.Base + data}
                                            height="300px"
                                            width="90%"
                                          ></iframe>
                                        </a>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </Col>
                        ))}
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default State
