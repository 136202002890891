import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Modal,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { URLS } from "../../Url"
import { useHistory } from "react-router-dom"

const Plans = () => {
  const [show, setshow] = useState(false)
  const [Plans, setPlans] = useState([])
  const [form, setform] = useState([])
  const [form1, setform1] = useState([])
  const [Files, setFiles] = useState("")
  const [Files1, setFiles1] = useState("")
  const [inputList, setInputList] = useState([""])
  const [inputList1, setInputList1] = useState([""])
  const [modal_small, setmodal_small] = useState(false)

  const handleInputChange = (e, index) => {
    const { value } = e.target
    const list = [...inputList]
    list[index] = value
    setInputList(list)
  }

  const handleRemoveClick = index => {
    const list = [...inputList]
    list.splice(index, 1)
    setInputList(list)
  }

  const handleAddClick = () => {
    setInputList([...inputList, ""])
  }

  const handleInputChange1 = (e, index) => {
    const { value } = e.target
    const list = [...inputList1]
    list[index] = value
    setInputList1(list)
  }

  const handleRemoveClick1 = index => {
    const list = [...inputList1]
    list.splice(index, 1)
    setInputList1(list)
  }

  const handleAddClick1 = () => {
    setInputList1([...inputList1, ""])
  }

  const changeHandler = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }
  const changeHandler1 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles1(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }

  function tog_small() {
    setmodal_small(!modal_small)
  }

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }
  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  useEffect(() => {
    GetAllPlans()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = Plans.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Plans.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const handleSubmit = e => {
    e.preventDefault()
    AddPlans()
  }

  const AddPlans = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("name", form.name)
    dataArray.append("validity", form.validity)
    dataArray.append("price", form.price)
    dataArray.append("offerprice", form.offerprice)
    dataArray.append("description", form.description)
    dataArray.append("userPlanName", form.userPlanName)   // adding
    dataArray.append("benfits", JSON.stringify(inputList))
    dataArray.append("type", "Vendor")

    for (let i = 0; i < Files.length; i++) {
      dataArray.append("image", Files[i])
    }

    axios
      .post(URLS.AddUserPlans, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetAllPlans()
            clearForm()
            setshow(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const EditPlans = () => {
    var token = datas
    var formid = form1._id
    const dataArray = new FormData()
    dataArray.append("name", form1.name)
    dataArray.append("validity", form1.validity)
    dataArray.append("price", form1.price)
    dataArray.append("offerprice", form1.offerprice)
    dataArray.append("description", form1.description)
    dataArray.append("userPlanName", form1.userPlanName)      // adding
    dataArray.append("benfits", JSON.stringify(inputList1))
    dataArray.append("type", "Vendor")

    for (let i = 0; i < Files1.length; i++) {
      dataArray.append("image", Files1[i])
    }
    axios
      .put(URLS.UpdateUserPlans + formid, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetAllPlans()
            setmodal_small(false)
            clearForm1()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const DeletePlans = data => {
    var token = datas
    var remid = data._id
    axios
      .delete(URLS.InActiveUserPlans + remid, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetAllPlans()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      DeletePlans(data)
    }
  }

  const handleSubmit1 = e => {
    e.preventDefault()
    EditPlans()
  }

  const GetAllPlans = () => {
    var token = datas
    axios
      .post(
        URLS.GetUserPlans,
        { type: "Vendor" },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setPlans(res.data.data)
        setIsLoading(false)
      })
  }

  const clearForm1 = () => {
    setFiles1({
      image: "",
    })
  }

  const clearForm = () => {
    setform({
      name: "",
      validity: "",
      price: "",
      offerprice: "",
      description: "",
    })
    setInputList([""])
    setFiles({
      image: "",
    })
  }

  const getpopup = data => {
    setform1(data)
    setInputList1(data.benfits)
    tog_small()
  }

  const [search, setsearch] = useState([])

  const searchAll = e => {
    let myUser = { ...search }
    myUser[e.target.name] = e.target.value
    setsearch(myUser)

    var token = datas
    axios
      .post(
        URLS.GetUserPlansSearch + `${e.target.value}`,
        { type: "Vendor" },

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setPlans(res.data.data)
      })
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const history = useHistory()

  const OrderId1 = data => {
    sessionStorage.setItem("Planid", data._id)
    history.push("/ViewVendorSubsriptionList")
  }

  const [info, setinfo] = useState(false)
  const [ins, setins] = useState(false)
  const [Instructions, setInstructions] = useState([])
  const [Information, setInformation] = useState([])

  function infos() {
    setinfo(!info)
  }

  function inst() {
    setins(!ins)
  }

  const getinfo = data => {
    setInformation(data.description)
    infos()
  }

  const getinc = data => {
    setInstructions(data.benfits)
    inst()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Go Cut" breadcrumbItem="Vendor Plans" />
          <Row>
            {show == true ? (
              <Col md={12}>
                <Card>
                  <CardHeader className="bg-white">
                    <CardTitle>Add Vendor Plan</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form
                      onSubmit={e => {
                        handleSubmit(e)
                      }}
                    >
                      <Row>
                        <Col md="6">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Plan Name <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="basicpill-firstname-input1"
                              placeholder="Enter Name"
                              required
                              name="name"
                              value={form1.name}
                              onChange={e => {
                                handleChange(e)
                              }}
                            />
                          </div>
                        </Col>
                        {/* adding */}
                        <Col md="6">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              User Plan Name <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="basicpill-firstname-input1"
                              placeholder="Enter User Plan Name"
                              required
                              name="userPlanName"
                              value={form1.userPlanName}
                              onChange={e => {
                                handleChange(e)
                              }}
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="mb-3">
                            <Label> Validity</Label>
                            <span className="text-danger">*</span>
                            <select
                              value={form.validity}
                              name="validity"
                              required
                              onChange={e => {
                                handleChange(e)
                              }}
                              className="form-select"
                            >
                              <option value="">Select</option>
                              <option value="1">1 Month</option>
                              <option value="3">3 Months</option>
                              <option value="6">6 Month</option>
                              <option value="9">9 Months</option>
                              <option value="12">12 Months</option>
                            </select>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Plan Price <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="basicpill-firstname-input1"
                              placeholder="Enter Plan Price "
                              required
                              name="price"
                              value={form.price}
                              onChange={e => {
                                handleChange(e)
                              }}
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Offer Price <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="basicpill-firstname-input1"
                              placeholder="Enter  Offer Price "
                              required
                              name="offerprice"
                              value={form.offerprice}
                              onChange={e => {
                                handleChange(e)
                              }}
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Image <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="file"
                              className="form-control"
                              id="basicpill-firstname-input1"
                              required
                              name="image"
                              value={Files.image}
                              onChange={changeHandler}
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Description <span className="text-danger">*</span>
                            </Label>
                            <textarea
                              type="text"
                              rows="1"
                              className="form-control "
                              id="basicpill-firstname-input1"
                              placeholder="Enter Description"
                              required
                              value={form.description}
                              name="description"
                              onChange={e => {
                                handleChange(e)
                              }}
                            />
                          </div>
                        </Col>
                        <div>
                          <Label>Benfits</Label>
                          <Row>
                            {inputList.map((x, i) => {
                              return (
                                <>
                                  <Row>
                                    <div key={i} className="box row">
                                      <Col md="4" sm="12" className="mb-3">
                                        <Input
                                          type="text"
                                          required
                                          name="benfits"
                                          placeholder="Enter Benfits"
                                          value={x}
                                          onChange={e =>
                                            handleInputChange(e, i)
                                          }
                                        />
                                      </Col>
                                      <Col sm="2" md="3">
                                        <div className="btn-box">
                                          {inputList.length !== 1 && (
                                            <button
                                              className="mr10 btn btn-outline-danger btn-sm m-1"
                                              type="button"
                                              onClick={() =>
                                                handleRemoveClick(i)
                                              }
                                            >
                                              Remove
                                              <i className="bx bx-x-circle"></i>
                                            </button>
                                          )}
                                          {inputList.length - 1 === i && (
                                            <button
                                              className="btn btn-sm btn-outline-info m-1"
                                              onClick={handleAddClick}
                                            >
                                              Add
                                              <i className="bx bx-plus-circle"></i>
                                            </button>
                                          )}
                                        </div>
                                      </Col>
                                    </div>
                                  </Row>
                                </>
                              )
                            })}
                          </Row>
                        </div>
                      </Row>
                      <Row>
                        <Col md="12">
                          <div style={{ float: "right" }}>
                            <Button color="primary" type="submit">
                              Submit <i className="fas fa-check-circle"></i>
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            ) : (
              ""
            )}
          </Row>
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <div>
                    <Row>
                      <Col>
                        <Button
                          onClick={() => {
                            setshow(!show)
                          }}
                          color="primary"
                        >
                          Add Vendor Plan
                          <i className="bx bx-user-plus"></i>
                        </Button>
                      </Col>
                      <Col>
                        <div style={{ float: "right" }}>
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Search.."
                            value={search.search}
                            onChange={searchAll}
                            name="search"
                          />
                        </div>
                      </Col>
                    </Row>
                    <div className="table-responsive">
                      <Table className="table table-bordered mb-4 mt-5">
                        <thead>
                          <tr className="text-center">
                            <th>S.No</th>
                            <th>Image</th>
                            <th>Plan Name</th>
                            <th>User Plan Name</th>
                            <th>Plan Validity</th>
                            <th>Plan Price </th>
                            <th>Offer Price</th>
                            <th>Description </th>
                            <th>Benfits </th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {lists.map((data, key) => (
                            <tr key={key} className="text-center">
                              <th>{(pageNumber - 1) * 5 + key + 6}</th>
                              <td>
                                <img
                                  style={{ width: "100px" }}
                                  src={URLS.Base + data.image}
                                />
                              </td>
                              <td>{data.name}</td>
                              <td>{data.userPlanName}</td>
                              <td>{data.validity}</td>
                              <td>{data.price}</td>
                              <td>{data.offerprice}</td>
                              <td>
                                <Button
                                  outline
                                  className="btn-sm"
                                  color="warning"
                                  onClick={() => {
                                    getinfo(data)
                                  }}
                                >
                                  <div className="d-flex">
                                    <i className="bx bxs-file px-1"></i>
                                    <small> View </small>
                                  </div>
                                </Button>
                              </td>
                              <td>
                                <Button
                                  outline
                                  color="warning"
                                  className="btn-sm"
                                  onClick={() => {
                                    getinc(data)
                                  }}
                                >
                                  <div className="d-flex">
                                    <i className="bx bxs-file px-1"></i>
                                    <small> View </small>
                                  </div>
                                </Button>
                              </td>
                              <td style={{ width: "250px" }}>
                                <Button
                                  onClick={() => {
                                    getpopup(data)
                                  }}
                                  size="sm"
                                  className="m-1"
                                  color="success"
                                >
                                  <div className="d-flex">
                                    <i className="bx bx-edit px-1"></i>
                                    <small> Edit </small>
                                  </div>
                                </Button>
                                <Button
                                  onClick={() => {
                                    OrderId1(data)
                                  }}
                                  size="sm"
                                  className="m-1"
                                  color="info"
                                >
                                  <div className="d-flex">
                                    <i className="fas fa-eye px-1"></i>
                                    <small> View </small>
                                  </div>
                                </Button>
                                <Button
                                  onClick={() => {
                                    manageDelete(data)
                                  }}
                                  color="danger"
                                  size="sm"
                                  className="m-1"
                                >
                                  <div className="d-flex">
                                    <i className="bx bx-trash px-1"></i>
                                    <small> Delete </small>
                                  </div>
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <div className="mt-3" style={{ float: "right" }}>
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          pageCount={pageCount}
                          onPageChange={changePage}
                          containerClassName={"pagination"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"disabled"}
                          activeClassName={"active"}
                          total={lists.length}
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal
          size="lg"
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Edit Vendor Plan
            </h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                handleSubmit1(e)
              }}
            >
              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Plan Name <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Name"
                      required
                      name="name"
                      value={form1.name}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </Col>
                {/* addibng */}
                <Col md="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                    User Plan Name <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter User Plan Name"
                      required
                      name="userPlanName"
                      value={form1.userPlanName}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label> Validity</Label>
                    <span className="text-danger">*</span>
                    <select
                      value={form1.validity}
                      name="validity"
                      required
                      onChange={e => {
                        handleChange1(e)
                      }}
                      className="form-select"
                    >
                      <option value="">Select</option>
                      <option value="1">1 Month</option>
                      <option value="3">3 Months</option>
                      <option value="6">6 Month</option>
                      <option value="9">9 Months</option>
                      <option value="12">12 Months</option>
                    </select>
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Plan Price <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Plan Price "
                      required
                      name="price"
                      value={form1.price}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Offer Price <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter  Offer Price "
                      required
                      name="offerprice"
                      value={form1.offerprice}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Image <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="file"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      name="image"
                      value={Files.image}
                      onChange={changeHandler1}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Description <span className="text-danger">*</span>
                    </Label>
                    <textarea
                      type="text"
                      rows="1"
                      className="form-control "
                      id="basicpill-firstname-input1"
                      placeholder="Enter Description"
                      required
                      value={form1.description}
                      name="description"
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </Col>
                <div>
                  <Label>Benfits</Label>
                  <Row>
                    {inputList1.map((x, i) => {
                      return (
                        <>
                          <Row>
                            <div key={i} className="box row">
                              <Col md="6" sm="12" className="mb-3">
                                <Input
                                  type="text"
                                  required
                                  name="benfits"
                                  placeholder="Enter Benfits"
                                  value={x}
                                  onChange={e => handleInputChange1(e, i)}
                                />
                              </Col>
                              <Col sm="2" md="3">
                                <div className="btn-box">
                                  {inputList1.length !== 1 && (
                                    <button
                                      className="mr10 btn btn-outline-danger btn-sm m-1"
                                      type="button"
                                      onClick={() => handleRemoveClick1(i)}
                                    >
                                      Remove
                                      <i className="bx bx-x-circle"></i>
                                    </button>
                                  )}
                                  {inputList1.length - 1 === i && (
                                    <button
                                      className="btn btn-sm btn-outline-info m-1"
                                      onClick={handleAddClick1}
                                    >
                                      Add
                                      <i className="bx bx-plus-circle"></i>
                                    </button>
                                  )}
                                </div>
                              </Col>
                            </div>
                          </Row>
                        </>
                      )
                    })}
                  </Row>
                </div>
              </Row>
              <div style={{ float: "right" }}>
                <Button color="primary" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
        <Modal
          size="md"
          isOpen={info}
          toggle={() => {
            infos()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              View Description :-
            </h5>
            <button
              onClick={() => {
                setinfo(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p className="pt-2">{Information}</p>
          </div>
        </Modal>
        <Modal
          size="md"
          isOpen={ins}
          toggle={() => {
            inst()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              View Benfits :-
            </h5>
            <button
              onClick={() => {
                setins(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {Instructions.map((data, i) => (
              <>
                <p key={i} className="pt-2">
                  {data}
                </p>
                <hr></hr>
              </>
            ))}
          </div>
        </Modal>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default Plans
