import React, { useState, useEffect } from "react"
import {
  CardBody,
  Container,
  Row,
  Col,
  Card,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import logo from "../../assets/images/logos.png"
import { ToastContainer } from "react-toastify"
import { useHistory } from "react-router-dom"
import ReactPaginate from "react-paginate"
import classnames from "classnames"
import { URLS } from "../../Url"
import axios from "axios"

function State() {
  const [forms, setforms] = useState([])

  const [activeTab1, setactiveTab1] = useState("5")

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }

  const history = useHistory()

  const [PendingBookings, setPendingBookings] = useState([])

  const [CompletedBookings, setCompletedBookings] = useState([])

  const [CancelledBooking, setCancelledBooking] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  useEffect(() => {
    GetOneOrder()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const OrderId = sessionStorage.getItem("UserIds")

  const GetOneOrder = () => {
    const data = {
      _id: OrderId,
    }

    var token = datas
    axios
      .post(URLS.GetOneUser, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setforms(res.data.userdetails)
        setPendingBookings(res.data.pendingBookings)
        setCompletedBookings(res.data.completedBookings)
        setCancelledBooking(res.data.cancelledBookings)
      })
  }

  const [listPerPage1] = useState(5)
  const [pageNumber1, setPageNumber1] = useState(0)

  const pagesVisited1 = pageNumber1 * listPerPage1
  const lists1 = PendingBookings.slice(
    pagesVisited1,
    pagesVisited1 + listPerPage1
  )
  const pageCount1 = Math.ceil(PendingBookings.length / listPerPage1)
  const changePage1 = ({ selected }) => {
    setPageNumber1(selected)
  }

  const [listPerPage2] = useState(5)
  const [pageNumber2, setPageNumber2] = useState(0)

  const pagesVisited2 = pageNumber2 * listPerPage2
  const lists2 = CompletedBookings.slice(
    pagesVisited2,
    pagesVisited2 + listPerPage2
  )
  const pageCount2 = Math.ceil(CompletedBookings.length / listPerPage2)
  const changePage2 = ({ selected }) => {
    setPageNumber2(selected)
  }

  const [listPerPage3] = useState(5)
  const [pageNumber3, setPageNumber3] = useState(0)

  const pagesVisited3 = pageNumber3 * listPerPage3
  const lists3 = CancelledBooking.slice(
    pagesVisited3,
    pagesVisited3 + listPerPage3
  )
  const pageCount3 = Math.ceil(CancelledBooking.length / listPerPage3)
  const changePage3 = ({ selected }) => {
    setPageNumber3(selected)
  }

  const Bookingsid = data => {
    sessionStorage.setItem("BookingId", data.orderId)
    history.push("/BookingView")
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Go Cut" breadcrumbItem="User Profile" />

          <Row>
            <Col md={12}>
              <Row>
                <Col md={12}>
                  <Button
                    onClick={() => history.goBack()}
                    className="mb-3  m-1 "
                    style={{ float: "right" }}
                    color="primary"
                  >
                    <i className="far fa-arrow-alt-circle-left"></i> Back
                  </Button>
                </Col>
              </Row>

              <Row className="mb-5">
                <Col md={12}>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md={12}>
                          <Nav pills className="navtab-bg nav-justified">
                            <NavItem>
                              <NavLink
                                style={{
                                  border: "1px #63183F solid",
                                  borderRadius: "5px",
                                  padding: "5px",
                                  margin: "4px",
                                  cursor: "pointer",
                                }}
                                className={classnames({
                                  active: activeTab1 === "5",
                                })}
                                onClick={() => {
                                  toggle1("5")
                                }}
                              >
                                Profile
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                style={{
                                  border: "1px #63183F solid",
                                  borderRadius: "5px",
                                  padding: "5px",
                                  margin: "4px",
                                  cursor: "pointer",
                                }}
                                className={classnames({
                                  active: activeTab1 === "7",
                                })}
                                onClick={() => {
                                  toggle1("7")
                                }}
                              >
                                Pending Booking
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                style={{
                                  border: "1px #63183F solid",
                                  borderRadius: "5px",
                                  padding: "5px",
                                  margin: "4px",
                                  cursor: "pointer",
                                }}
                                className={classnames({
                                  active: activeTab1 === "8",
                                })}
                                onClick={() => {
                                  toggle1("8")
                                }}
                              >
                                Completed Bookings
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                style={{
                                  border: "1px #63183F solid",
                                  borderRadius: "5px",
                                  padding: "5px",
                                  margin: "4px",
                                  cursor: "pointer",
                                }}
                                className={classnames({
                                  active: activeTab1 === "9",
                                })}
                                onClick={() => {
                                  toggle1("9")
                                }}
                              >
                                Cancelled Booking
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </Col>
                        <Col md={12}>
                          <TabContent
                            activeTab={activeTab1}
                            className="p-4  text-muted"
                          >
                            <TabPane tabId="5">
                              <h5 className="mb-3">Profile Details : </h5>
                              <Row>
                                <Col lg={4} className="mt-3">
                                  {forms?.profilePic == "" ? (
                                    <>
                                      <img
                                        src={logo}
                                        style={{
                                          height: "100px",
                                          width: "100px",
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        src={URLS.Base + forms?.profilePic}
                                        style={{
                                          height: "100px",
                                          width: "100px",
                                        }}
                                      />
                                    </>
                                  )}
                                </Col>
                                <Col lg={4}>
                                  <ul className="verti-timeline list-unstyled">
                                    <li className="event-list mt-4">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Name
                                            </h6>
                                            <p className="text-muted">
                                              {forms?.name}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>{" "}
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Mobile Number
                                            </h6>
                                            <p className="text-muted">
                                              {forms?.mobileNumber}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Email
                                            </h6>
                                            <p className="text-muted">
                                              {forms?.email}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </Col>
                                <Col lg={4}>
                                  <ul className="verti-timeline list-unstyled">
                                    <li className="event-list mt-4">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Date Of Joning:
                                            </h6>
                                            <p className="text-muted">
                                              {forms?.logCreatedDate?.slice(
                                                0,
                                                10
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>{" "}
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Gender
                                            </h6>
                                            <p className="text-muted">
                                              {forms?.gender}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Age
                                            </h6>
                                            <p className="text-muted">
                                              {forms.age}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </Col>
                              </Row>
                            </TabPane>
                            <TabPane tabId="7">
                              <h5
                                className="mb-3 text-primary "
                                style={{ textDecoration: "underline" }}
                              >
                                Pending Bookings
                              </h5>
                              <div className="table-responsive">
                                <Table className="table table-bordered mb-2 mt-3">
                                  <thead>
                                    <tr className="text-center">
                                      <th>S.No</th>
                                      <th>Date</th>
                                      <th>Booking Id</th>
                                      <th>Salon Name</th>
                                      <th>Salon Phone</th>
                                      <th>Status</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {lists1?.map((data, key) => (
                                      <tr key={key} className="text-center">
                                        <td>
                                          {(pageNumber1 - 1) * 5 + key + 6}
                                        </td>
                                        <td>{data.date}</td>
                                        <td>{data.bookingId}</td>
                                        <td>{data.salonName}</td>
                                        <td>{data.salonMobileNumber}</td>
                                        <td>
                                          <span className="badge bg-warning">
                                            {data.status}
                                          </span>
                                        </td>
                                        <td>
                                          <Button
                                            className="mr-2"
                                            style={{
                                              padding: "6px",
                                              margin: "3px",
                                            }}
                                            onClick={() => {
                                              Bookingsid(data)
                                            }}
                                            color="info"
                                            outline
                                          >
                                            <i className="fas fa-eye"></i>
                                          </Button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                                <div
                                  className="d-flex mt-3 mb-1"
                                  style={{ float: "right" }}
                                >
                                  <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    pageCount={pageCount1}
                                    onPageChange={changePage1}
                                    containerClassName={"pagination"}
                                    previousLinkClassName={"previousBttn"}
                                    nextLinkClassName={"nextBttn"}
                                    disabledClassName={"disabled"}
                                    activeClassName={"active"}
                                    total={lists1?.length}
                                  />
                                </div>
                              </div>
                            </TabPane>
                            <TabPane tabId="8">
                              <h5
                                className="mb-3 text-primary "
                                style={{ textDecoration: "underline" }}
                              >
                                Completed Bookings
                              </h5>

                              <div className="table-responsive">
                                <Table className="table table-bordered mb-2 mt-3">
                                  <thead>
                                    <tr className="text-center">
                                      <th>S.No</th>
                                      <th>Date</th>
                                      <th>Booking Id</th>
                                      <th>Salon Name</th>
                                      <th>Salon Phone</th>
                                      <th>Status</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {lists2?.map((data, key) => (
                                      <tr key={key} className="text-center">
                                        <td>
                                          {(pageNumber2 - 1) * 5 + key + 6}
                                        </td>
                                        <td>{data.date}</td>
                                        <td>{data.bookingId}</td>
                                        <td>{data.salonName}</td>
                                        <td>{data.salonMobileNumber}</td>
                                        <td>
                                          <span className="badge bg-warning">
                                            {data.status}
                                          </span>
                                        </td>
                                        <td>
                                          <Button
                                            className="mr-2"
                                            style={{
                                              padding: "6px",
                                              margin: "3px",
                                            }}
                                            onClick={() => {
                                              Bookingsid(data)
                                            }}
                                            color="info"
                                            outline
                                          >
                                            <i className="fas fa-eye"></i>
                                          </Button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                                <div
                                  className="d-flex mt-3 mb-1"
                                  style={{ float: "right" }}
                                >
                                  <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    pageCount={pageCount2}
                                    onPageChange={changePage2}
                                    containerClassName={"pagination"}
                                    previousLinkClassName={"previousBttn"}
                                    nextLinkClassName={"nextBttn"}
                                    disabledClassName={"disabled"}
                                    activeClassName={"active"}
                                    total={lists2?.length}
                                  />
                                </div>
                              </div>
                            </TabPane>
                            <TabPane tabId="9">
                              <h5
                                className="mb-3 text-primary "
                                style={{ textDecoration: "underline" }}
                              >
                                Cancelled Bookings
                              </h5>{" "}
                              <div className="table-responsive">
                                <Table className="table table-bordered mb-2 mt-3">
                                  <thead>
                                    <tr className="text-center">
                                      <th>S.No</th>
                                      <th>Date</th>
                                      <th>Booking Id</th>
                                      <th>Salon Name</th>
                                      <th>Salon Phone</th>
                                      <th>Status</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {lists3?.map((data, key) => (
                                      <tr key={key} className="text-center">
                                        <td>
                                          {(pageNumber3 - 1) * 5 + key + 6}
                                        </td>
                                        <td>{data.date}</td>
                                        <td>{data.bookingId}</td>
                                        <td>{data.salonName}</td>
                                        <td>{data.salonMobileNumber}</td>
                                        <td>
                                          <span className="badge bg-warning">
                                            {data.status}
                                          </span>
                                        </td>
                                        <td>
                                          <Button
                                            className="mr-2"
                                            style={{
                                              padding: "6px",
                                              margin: "3px",
                                            }}
                                            onClick={() => {
                                              Bookingsid(data)
                                            }}
                                            color="info"
                                            outline
                                          >
                                            <i className="fas fa-eye"></i>
                                          </Button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                                <div
                                  className="d-flex mt-3 mb-1"
                                  style={{ float: "right" }}
                                >
                                  <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    pageCount={pageCount3}
                                    onPageChange={changePage3}
                                    containerClassName={"pagination"}
                                    previousLinkClassName={"previousBttn"}
                                    nextLinkClassName={"nextBttn"}
                                    disabledClassName={"disabled"}
                                    activeClassName={"active"}
                                    total={lists3?.length}
                                  />
                                </div>
                              </div>
                            </TabPane>
                          </TabContent>
                        </Col>{" "}
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default State
